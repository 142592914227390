<template>
  <Cascader
    ref="cascaderRef"
    @search="handleSearch"
    title="设施分类列表"
    :initList="facilityList"
    :cb-select="facilityItem"
    select-type="radio"
    :cbGoBack="facilityBack"
    @confirm="confirm"
  />
</template>

<script>
import Cascader from '@/components/Cascader'
import api from '@/api/index'
import {
  getPreMadeQRCodeHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/preMadeQRCodeOfflineApiHandler.js'

export default {
  components: { Cascader },
  data () {
    return {
      facilityList: [],
      preMadeQRCodeOfflineData: null
    }
  },
  created () {
    this.renderFacility()
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    }
  },
  watch: {
    isOffline () {
      this.$refs.cascaderRef.clearSelectData()
      this.facilityList = []
      this.renderFacility()
    }
  },
  methods: {
    confirm (selectCodes, selectDetails) {
      console.log('selectCodes, selectDetails: ', selectCodes, selectDetails)
      // this.$EB.$emit(this.$EBT.ProjectList, selectDetails[0])
      this.$EB.$emit(this.$EBT.FacilityClass, selectDetails[0])
    },
    // 设施分类下拉
    facilityItem (item, breadCrumbsList) {
      if (breadCrumbsList.length === 1 && item.children) {
        if (this.isOffline) {
          this.$http
            .get(api.facilityTree, { name: item.name, status: 1 })
            .then(res => {
              const newList = this.transformCausesListF(res[0].children || [])
              this.$set(this.facilityList[item.index], 'children', newList)
            })
        }
        return true
      } else if (!item.children) {
        // this.$EB.$emit(this.$EBT.FacilityClass, item)
      }
    },
    async renderFacility () {
      if (this.isOffline) {
        const data = await this.$http.get(api.QRCodeSel + '/2/0')
        this.facilityList = data.reduce((arr, item) => {
          arr.push({ code: item.code, codeName: item.name, children: [] })
          return arr
        }, [])
      } else {
        const preMadeQRCodeOfflineData = await getPreMadeQRCodeHandler()
        if (preMadeQRCodeOfflineData) {
          this.preMadeQRCodeOfflineData = preMadeQRCodeOfflineData
          const { facilityCategoryTrees = [] } = this.lodash.cloneDeep(this.preMadeQRCodeOfflineData)
          facilityCategoryTrees.forEach((item) => {
            item.codeName = item.name
            item.children = this.transformCausesListF(item.children || [])
          })
          this.facilityList = facilityCategoryTrees
        } else {
          this.$notify({
            type: 'warning',
            message: '您的网络状态不好，请提前下载离线数据'
          })
        }
      }
    },
    transformCausesListF (source) {
      let arr = []
      source.forEach(item => {
        const { code, name, children, ...moreVal } = item
        let child
        if (children && children.length > 0) {
          child = this.transformCausesListF(children)
        }
        arr.push({ code: code, codeName: name, children: child, ...moreVal })
      })
      return arr
    },
    facilityBack () {
      this.$router.back()
    },
    async handleSearch ({ searchValue }) {
      if (!searchValue) return
      let res
      if (this.isOffline) {
        res = await this.$http.get(api.facilityTree)
      } else {
        const { facilityCategoryTrees = [] } = this.lodash.cloneDeep(this.preMadeQRCodeOfflineData)
        res = facilityCategoryTrees
      }
      const newList = this.transformCausesListF(res[0].children || [])
      // 先递归获取所有子节点 然后匹配搜索的值 渲染
      this.treeChild = []
      this.searchVal = []
      this.tree(newList)
      this.treeChild.forEach(i => {
        if (i.codeName.indexOf(searchValue) !== -1) {
          this.searchVal.push(i)
        }
      })
      this.$refs.cascaderRef.setSearchList(
        this.searchVal
        // newList.reduce((arr, item) => {
        // if (item.codeName.indexOf(searchValue) !== -1) {
        //   return arr.concat(item.children)
        // }
        // return arr
        // }, [])
      )
    },
    // 所有的子节点
    tree (tree) {
      tree.forEach(item => {
        if (!item.children) {
          this.treeChild.push(item)
        } else {
          this.tree(item.children)
        }
      })
    }
  }
}
</script>

<style></style>
