var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("van-nav-bar", {
        staticClass: "nav nav-header-home",
        attrs: { fixed: "", "z-index": "999", placeholder: true },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("span", { staticClass: "nav-title-text" }, [
                  _vm._v("设施/设备台账"),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "left",
            fn: function () {
              return [
                _c("div", {
                  staticClass: "icon-back",
                  on: { click: _vm.toBack },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "van-tabs",
        {
          attrs: {
            color: "#3366FE",
            "title-inactive-color": "#7C7F8A",
            "title-active-color": "#3366FE",
            "line-height": "2px",
          },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        [
          _c(
            "van-tab",
            {
              attrs: {
                "title-class": "tabs-title",
                title: "设备",
                name: "equip",
              },
            },
            [
              _c(
                "van-cell-group",
                [
                  _c("van-field", {
                    attrs: {
                      required: "",
                      label: "所在项目",
                      readonly: "",
                      placeholder: "请选择",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.projectShowM("equip")
                      },
                    },
                    model: {
                      value: _vm.equipForm.projectName,
                      callback: function ($$v) {
                        _vm.$set(_vm.equipForm, "projectName", $$v)
                      },
                      expression: "equipForm.projectName",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      required: "",
                      label: "所在组团",
                      readonly: "",
                      placeholder: "请选择",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.groupModal("equip")
                      },
                    },
                    model: {
                      value: _vm.equipForm.projectGroupName,
                      callback: function ($$v) {
                        _vm.$set(_vm.equipForm, "projectGroupName", $$v)
                      },
                      expression: "equipForm.projectGroupName",
                    },
                  }),
                  _c(
                    "van-popup",
                    {
                      attrs: {
                        position: "bottom",
                        "close-on-click-overlay": false,
                      },
                      model: {
                        value: _vm.showGruop,
                        callback: function ($$v) {
                          _vm.showGruop = $$v
                        },
                        expression: "showGruop",
                      },
                    },
                    [
                      _c("van-picker", {
                        attrs: {
                          "show-toolbar": "",
                          columns: _vm.groupOptions,
                          "value-key": "full_name",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.groupOK($event, "equipForm")
                          },
                          cancel: function ($event) {
                            _vm.showGruop = false
                          },
                        },
                      }),
                      _c(
                        "van-row",
                        { staticClass: "col-inp" },
                        [
                          _c(
                            "van-col",
                            { attrs: { span: "10" } },
                            [
                              _c("van-field", {
                                attrs: { placeholder: "请输入组团代码" },
                                model: {
                                  value: _vm.equipForm.groupCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.equipForm, "groupCode", $$v)
                                  },
                                  expression: "equipForm.groupCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "van-col",
                            { attrs: { span: "9" } },
                            [
                              _c("van-field", {
                                attrs: { placeholder: "请输入组团名称" },
                                model: {
                                  value: _vm.equipForm.groupName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.equipForm, "groupName", $$v)
                                  },
                                  expression: "equipForm.groupName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "van-col",
                            { attrs: { span: "5" } },
                            [
                              _c(
                                "van-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.createGroups("equipForm")
                                    },
                                  },
                                },
                                [_vm._v("手动创建")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("van-field", {
                    attrs: {
                      required: "",
                      label: "设备分类",
                      placeholder: "请选择",
                    },
                    on: { click: _vm.equipShowM },
                    model: {
                      value: _vm.equipForm.categoryName,
                      callback: function ($$v) {
                        _vm.$set(_vm.equipForm, "categoryName", $$v)
                      },
                      expression: "equipForm.categoryName",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      required: "",
                      label: "设备名称",
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.equipForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.equipForm, "name", $$v)
                      },
                      expression: "equipForm.name",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      required: "",
                      label: "设备编码",
                      placeholder: "请输入",
                      disabled: !_vm.isOffline,
                    },
                    model: {
                      value: _vm.equipForm.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.equipForm, "code", $$v)
                      },
                      expression: "equipForm.code",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      required: "",
                      label: "接管日期",
                      placeholder: "请选择",
                      readonly: "",
                      clickable: "",
                    },
                    on: {
                      click: function ($event) {
                        _vm.showDate = true
                      },
                    },
                    model: {
                      value: _vm.equipForm.takeoverTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.equipForm, "takeoverTime", $$v)
                      },
                      expression: "equipForm.takeoverTime",
                    },
                  }),
                  _c(
                    "van-popup",
                    {
                      attrs: {
                        position: "bottom",
                        "close-on-click-overlay": false,
                      },
                      model: {
                        value: _vm.showDate,
                        callback: function ($$v) {
                          _vm.showDate = $$v
                        },
                        expression: "showDate",
                      },
                    },
                    [
                      _c("van-datetime-picker", {
                        attrs: {
                          "min-date": _vm.minDate,
                          type: "date",
                          "max-date": _vm.maxDate,
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.dateOK($event, "equipForm")
                          },
                          cancel: function ($event) {
                            _vm.showDate = false
                          },
                        },
                        model: {
                          value: _vm.maxCurrentDate,
                          callback: function ($$v) {
                            _vm.maxCurrentDate = $$v
                          },
                          expression: "maxCurrentDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("van-field", {
                    attrs: {
                      required: "",
                      label: "设备品牌",
                      placeholder: "请选择",
                      readonly: "",
                      clickable: "",
                    },
                    on: {
                      click: function ($event) {
                        _vm.showBrand = true
                      },
                    },
                    model: {
                      value: _vm.equipForm.brandName,
                      callback: function ($$v) {
                        _vm.$set(_vm.equipForm, "brandName", $$v)
                      },
                      expression: "equipForm.brandName",
                    },
                  }),
                  _c(
                    "van-popup",
                    {
                      attrs: {
                        position: "bottom",
                        "close-on-click-overlay": false,
                      },
                      model: {
                        value: _vm.showBrand,
                        callback: function ($$v) {
                          _vm.showBrand = $$v
                        },
                        expression: "showBrand",
                      },
                    },
                    [
                      _c("van-picker", {
                        attrs: {
                          "show-toolbar": "",
                          columns: _vm.brandOptions,
                          "value-key": "makeName",
                        },
                        on: {
                          confirm: _vm.BrandOK,
                          cancel: function ($event) {
                            _vm.showBrand = false
                          },
                        },
                      }),
                      _c("van-field", {
                        attrs: { placeholder: "手动输入" },
                        scopedSlots: _vm._u([
                          {
                            key: "button",
                            fn: function () {
                              return [
                                _c(
                                  "van-button",
                                  {
                                    attrs: { size: "small", type: "primary" },
                                    on: { click: _vm.creatBrand },
                                  },
                                  [_vm._v("手动创建 ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.equipForm.brandName,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipForm, "brandName", $$v)
                          },
                          expression: "equipForm.brandName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.calcFormType === 1
                    ? _c("van-field", {
                        attrs: {
                          placeholder: "请输入",
                          required: "",
                          label: "设备数量",
                          type: "digit",
                        },
                        model: {
                          value: _vm.equipForm.deviceNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipForm, "deviceNum", $$v)
                          },
                          expression: "equipForm.deviceNum",
                        },
                      })
                    : _vm._e(),
                  _vm.calcFormType === 2
                    ? _c("van-field", {
                        attrs: {
                          required: "",
                          label: "型号或规格",
                          placeholder: "请输入",
                        },
                        model: {
                          value: _vm.equipForm.modelSpec,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipForm, "modelSpec", $$v)
                          },
                          expression: "equipForm.modelSpec",
                        },
                      })
                    : _vm._e(),
                  _c("van-field", {
                    attrs: {
                      required: "",
                      label: "维保频次",
                      placeholder: "请选择",
                      readonly: "",
                      clickable: "",
                    },
                    on: {
                      click: function ($event) {
                        _vm.showCount = true
                      },
                    },
                    model: {
                      value: _vm.equipForm.maintenanceTimeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.equipForm, "maintenanceTimeName", $$v)
                      },
                      expression: "equipForm.maintenanceTimeName",
                    },
                  }),
                  _c(
                    "van-popup",
                    {
                      attrs: {
                        position: "bottom",
                        "close-on-click-overlay": false,
                      },
                      model: {
                        value: _vm.showCount,
                        callback: function ($$v) {
                          _vm.showCount = $$v
                        },
                        expression: "showCount",
                      },
                    },
                    [
                      _c("van-picker", {
                        attrs: {
                          "show-toolbar": "",
                          columns: _vm.countOptions,
                          "value-key": "name",
                        },
                        on: {
                          confirm: _vm.countOK,
                          cancel: function ($event) {
                            _vm.showCount = false
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.calcFormType === 2
                    ? [
                        _c("van-field", {
                          attrs: {
                            required: "",
                            label: "出厂日期",
                            placeholder: "请选择",
                            readonly: "",
                            clickable: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showOutDate = true
                            },
                          },
                          model: {
                            value: _vm.equipForm.pdDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipForm, "pdDate", $$v)
                            },
                            expression: "equipForm.pdDate",
                          },
                        }),
                        _c(
                          "van-popup",
                          {
                            attrs: {
                              position: "bottom",
                              "close-on-click-overlay": false,
                            },
                            model: {
                              value: _vm.showOutDate,
                              callback: function ($$v) {
                                _vm.showOutDate = $$v
                              },
                              expression: "showOutDate",
                            },
                          },
                          [
                            _c("van-datetime-picker", {
                              attrs: { "min-date": _vm.minDate, type: "date" },
                              on: {
                                confirm: _vm.outDateOK,
                                cancel: function ($event) {
                                  _vm.showOutDate = false
                                },
                              },
                              model: {
                                value: _vm.currentDate,
                                callback: function ($$v) {
                                  _vm.currentDate = $$v
                                },
                                expression: "currentDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("van-field", {
                          attrs: {
                            required: "",
                            label: "合同质保到期日",
                            placeholder: "请选择",
                            readonly: "",
                            clickable: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showContDate = true
                            },
                          },
                          model: {
                            value: _vm.equipForm.expiryDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipForm, "expiryDate", $$v)
                            },
                            expression: "equipForm.expiryDate",
                          },
                        }),
                        _c(
                          "van-popup",
                          {
                            attrs: {
                              position: "bottom",
                              "close-on-click-overlay": false,
                            },
                            model: {
                              value: _vm.showContDate,
                              callback: function ($$v) {
                                _vm.showContDate = $$v
                              },
                              expression: "showContDate",
                            },
                          },
                          [
                            _c("van-datetime-picker", {
                              attrs: { "min-date": _vm.minDate, type: "date" },
                              on: {
                                confirm: _vm.contDateOK,
                                cancel: function ($event) {
                                  _vm.showContDate = false
                                },
                              },
                              model: {
                                value: _vm.currentDate,
                                callback: function ($$v) {
                                  _vm.currentDate = $$v
                                },
                                expression: "currentDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("van-field", {
                          attrs: {
                            required: "",
                            label: "是否监控",
                            readonly: "",
                            clickable: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showMonitor = true
                            },
                          },
                          model: {
                            value: _vm.equipForm.isMonitorName,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipForm, "isMonitorName", $$v)
                            },
                            expression: "equipForm.isMonitorName",
                          },
                        }),
                        _c(
                          "van-popup",
                          {
                            attrs: {
                              position: "bottom",
                              "close-on-click-overlay": false,
                            },
                            model: {
                              value: _vm.showMonitor,
                              callback: function ($$v) {
                                _vm.showMonitor = $$v
                              },
                              expression: "showMonitor",
                            },
                          },
                          [
                            _c("van-picker", {
                              attrs: {
                                "show-toolbar": "",
                                columns: _vm.isMonitorOptions,
                                "value-key": "label",
                              },
                              on: {
                                confirm: function ($event) {
                                  return _vm.monitorOK($event, "equipForm")
                                },
                                cancel: function ($event) {
                                  _vm.showMonitor = false
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("van-field", {
                          attrs: {
                            required: "",
                            type: "number",
                            label: "设备价值(元)",
                            placeholder: "请输入",
                          },
                          model: {
                            value: _vm.equipForm.deviceAmount,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipForm, "deviceAmount", $$v)
                            },
                            expression: "equipForm.deviceAmount",
                          },
                        }),
                        _c("van-field", {
                          attrs: {
                            required: "",
                            type: "digit",
                            label: "检验周期(年)",
                            placeholder: "请输入",
                          },
                          model: {
                            value: _vm.equipForm.checkoutTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipForm, "checkoutTime", $$v)
                            },
                            expression: "equipForm.checkoutTime",
                          },
                        }),
                        _c("van-field", {
                          attrs: {
                            required: "",
                            label: "上次检验日期",
                            placeholder: "请选择",
                            readonly: "",
                            clickable: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showCheckDate = true
                            },
                          },
                          model: {
                            value: _vm.equipForm.lastCheckoutTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipForm, "lastCheckoutTime", $$v)
                            },
                            expression: "equipForm.lastCheckoutTime",
                          },
                        }),
                        _c(
                          "van-popup",
                          {
                            attrs: {
                              position: "bottom",
                              "close-on-click-overlay": false,
                            },
                            model: {
                              value: _vm.showCheckDate,
                              callback: function ($$v) {
                                _vm.showCheckDate = $$v
                              },
                              expression: "showCheckDate",
                            },
                          },
                          [
                            _c("van-datetime-picker", {
                              attrs: {
                                "min-date": _vm.minDate,
                                type: "date",
                                "max-date": _vm.maxDate,
                              },
                              on: {
                                confirm: _vm.checkDateOK,
                                cancel: function ($event) {
                                  _vm.showCheckDate = false
                                },
                              },
                              model: {
                                value: _vm.maxCurrentDate,
                                callback: function ($$v) {
                                  _vm.maxCurrentDate = $$v
                                },
                                expression: "maxCurrentDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._l(_vm.equipForm.extras, function (item, index) {
                          return _c("van-field", {
                            key: index,
                            attrs: {
                              label: item.name,
                              required: index === 0,
                              placeholder: "请选择",
                              readonly: "",
                              clickable: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showExtrasM(index)
                              },
                            },
                            model: {
                              value: _vm.equipForm.extras[index].model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.equipForm.extras[index],
                                  "model",
                                  $$v
                                )
                              },
                              expression: "equipForm.extras[index].model",
                            },
                          })
                        }),
                        _c(
                          "van-popup",
                          {
                            attrs: {
                              position: "bottom",
                              "close-on-click-overlay": false,
                            },
                            model: {
                              value: _vm.showExtras,
                              callback: function ($$v) {
                                _vm.showExtras = $$v
                              },
                              expression: "showExtras",
                            },
                          },
                          [
                            _c("van-picker", {
                              attrs: {
                                "show-toolbar": "",
                                columns: _vm.equipForm.extras[_vm.extrasIndex]
                                  .value
                                  ? (
                                      _vm.equipForm.extras[_vm.extrasIndex]
                                        .value || []
                                    ).split(",")
                                  : [],
                              },
                              on: {
                                confirm: _vm.extrasOK,
                                cancel: function ($event) {
                                  _vm.showExtras = false
                                },
                              },
                            }),
                            _c("van-field", {
                              attrs: { placeholder: "手动输入" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button",
                                    fn: function () {
                                      return [
                                        _c(
                                          "van-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.showExtras = false
                                              },
                                            },
                                          },
                                          [_vm._v("手动创建 ")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1558309130
                              ),
                              model: {
                                value:
                                  _vm.equipForm.extras[_vm.extrasIndex].model,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.equipForm.extras[_vm.extrasIndex],
                                    "model",
                                    $$v
                                  )
                                },
                                expression:
                                  "equipForm.extras[extrasIndex].model",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c("p", { staticClass: "bg-line" }),
                  _c("van-field", {
                    staticClass: "form-textarea",
                    attrs: {
                      label: "设备位置",
                      placeholder: "请输入",
                      rows: "2",
                      type: "textarea",
                      required: "",
                    },
                    model: {
                      value: _vm.equipForm.location,
                      callback: function ($$v) {
                        _vm.$set(_vm.equipForm, "location", $$v)
                      },
                      expression: "equipForm.location",
                    },
                  }),
                  _c("p", { staticClass: "bg-line" }),
                  _c("van-field", {
                    staticClass: "form-textarea",
                    attrs: {
                      label: "设备说明",
                      placeholder: "请输入",
                      rows: "2",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.equipForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.equipForm, "remark", $$v)
                      },
                      expression: "equipForm.remark",
                    },
                  }),
                  _c("p", { staticClass: "bg-line" }),
                  _c(
                    "div",
                    { staticClass: "img-upload" },
                    [
                      _c("common-upload", {
                        ref: "offlineUpload_1",
                        attrs: {
                          file: _vm.equipForm.attachementModelList,
                          "enable-watermark": false,
                          "enable-album": true,
                          "enable-edit": false,
                        },
                        on: {
                          "update:file": function ($event) {
                            return _vm.$set(
                              _vm.equipForm,
                              "attachementModelList",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "van-tab",
            {
              attrs: {
                "title-class": "tabs-title",
                title: "设施",
                name: "facility",
              },
            },
            [
              _c(
                "van-cell-group",
                [
                  _c("van-field", {
                    attrs: {
                      required: "",
                      label: "所在项目",
                      readonly: "",
                      placeholder: "请选择",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.projectShowM("facility")
                      },
                    },
                    model: {
                      value: _vm.facilityForm.projectName,
                      callback: function ($$v) {
                        _vm.$set(_vm.facilityForm, "projectName", $$v)
                      },
                      expression: "facilityForm.projectName",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      required: "",
                      label: "所在组团",
                      placeholder: "请选择",
                      readonly: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.groupModal("facility")
                      },
                    },
                    model: {
                      value: _vm.facilityForm.projectGroupName,
                      callback: function ($$v) {
                        _vm.$set(_vm.facilityForm, "projectGroupName", $$v)
                      },
                      expression: "facilityForm.projectGroupName",
                    },
                  }),
                  _c(
                    "van-popup",
                    {
                      attrs: {
                        position: "bottom",
                        "close-on-click-overlay": false,
                      },
                      model: {
                        value: _vm.showGruop,
                        callback: function ($$v) {
                          _vm.showGruop = $$v
                        },
                        expression: "showGruop",
                      },
                    },
                    [
                      _c("van-picker", {
                        attrs: {
                          "show-toolbar": "",
                          columns: _vm.groupOptionsF,
                          "value-key": "full_name",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.groupOK($event, "facilityForm")
                          },
                          cancel: function ($event) {
                            _vm.showGruop = false
                          },
                        },
                      }),
                      _c(
                        "van-row",
                        { staticClass: "col-inp" },
                        [
                          _c(
                            "van-col",
                            { attrs: { span: "10" } },
                            [
                              _c("van-field", {
                                attrs: { placeholder: "请输入组团代码" },
                                model: {
                                  value: _vm.facilityForm.groupCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facilityForm, "groupCode", $$v)
                                  },
                                  expression: "facilityForm.groupCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "van-col",
                            { attrs: { span: "9" } },
                            [
                              _c("van-field", {
                                attrs: { placeholder: "请输入组团名称" },
                                model: {
                                  value: _vm.facilityForm.groupName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facilityForm, "groupName", $$v)
                                  },
                                  expression: "facilityForm.groupName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "van-col",
                            { attrs: { span: "5" } },
                            [
                              _c(
                                "van-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.createGroups("facilityForm")
                                    },
                                  },
                                },
                                [_vm._v("手动创建")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("van-field", {
                    attrs: {
                      required: "",
                      label: "设施分类",
                      placeholder: "请选择",
                    },
                    on: { click: _vm.facilityShowM },
                    model: {
                      value: _vm.facilityForm.categoryName,
                      callback: function ($$v) {
                        _vm.$set(_vm.facilityForm, "categoryName", $$v)
                      },
                      expression: "facilityForm.categoryName",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      required: "",
                      label: "设施名称",
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.facilityForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.facilityForm, "name", $$v)
                      },
                      expression: "facilityForm.name",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      required: "",
                      label: "设施编码",
                      disabled: !_vm.isOffline,
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.facilityForm.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.facilityForm, "code", $$v)
                      },
                      expression: "facilityForm.code",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      required: "",
                      label: "接管日期",
                      placeholder: "请选择",
                      readonly: "",
                      clickable: "",
                    },
                    on: {
                      click: function ($event) {
                        _vm.showDate = true
                      },
                    },
                    model: {
                      value: _vm.facilityForm.takeoverTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.facilityForm, "takeoverTime", $$v)
                      },
                      expression: "facilityForm.takeoverTime",
                    },
                  }),
                  _c(
                    "van-popup",
                    {
                      attrs: {
                        position: "bottom",
                        "close-on-click-overlay": false,
                      },
                      model: {
                        value: _vm.showDate,
                        callback: function ($$v) {
                          _vm.showDate = $$v
                        },
                        expression: "showDate",
                      },
                    },
                    [
                      _c("van-datetime-picker", {
                        attrs: {
                          "min-date": _vm.minDate,
                          type: "date",
                          "max-date": _vm.maxDate,
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.dateOK($event, "facilityForm")
                          },
                          cancel: function ($event) {
                            _vm.showDate = false
                          },
                        },
                        model: {
                          value: _vm.maxCurrentDate,
                          callback: function ($$v) {
                            _vm.maxCurrentDate = $$v
                          },
                          expression: "maxCurrentDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.facilityType === 1
                    ? _c("van-field", {
                        attrs: {
                          required: "",
                          label: "设施数量",
                          placeholder: "请输入",
                        },
                        model: {
                          value: _vm.facilityForm.deviceNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.facilityForm, "deviceNum", $$v)
                          },
                          expression: "facilityForm.deviceNum",
                        },
                      })
                    : _vm._e(),
                  _vm.facilityType === 2
                    ? _c("van-field", {
                        attrs: {
                          required: "",
                          label: "是否监控",
                          readonly: "",
                          clickable: "",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showMonitor = true
                          },
                        },
                        model: {
                          value: _vm.facilityForm.isMonitorName,
                          callback: function ($$v) {
                            _vm.$set(_vm.facilityForm, "isMonitorName", $$v)
                          },
                          expression: "facilityForm.isMonitorName",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "van-popup",
                    {
                      attrs: {
                        position: "bottom",
                        "close-on-click-overlay": false,
                      },
                      model: {
                        value: _vm.showMonitor,
                        callback: function ($$v) {
                          _vm.showMonitor = $$v
                        },
                        expression: "showMonitor",
                      },
                    },
                    [
                      _c("van-picker", {
                        attrs: {
                          "show-toolbar": "",
                          columns: _vm.isMonitorOptions,
                          "value-key": "label",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.monitorOK($event, "facilityForm")
                          },
                          cancel: function ($event) {
                            _vm.showMonitor = false
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "bg-line" }),
                  _c("van-field", {
                    staticClass: "form-textarea",
                    attrs: {
                      label: "设施位置",
                      rows: "2",
                      placeholder: "请输入",
                      type: "textarea",
                      required: "",
                    },
                    model: {
                      value: _vm.facilityForm.location,
                      callback: function ($$v) {
                        _vm.$set(_vm.facilityForm, "location", $$v)
                      },
                      expression: "facilityForm.location",
                    },
                  }),
                  _c("p", { staticClass: "bg-line" }),
                  _c("van-field", {
                    staticClass: "form-textarea",
                    attrs: {
                      label: "设施说明",
                      rows: "2",
                      placeholder: "请输入",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.facilityForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.facilityForm, "remark", $$v)
                      },
                      expression: "facilityForm.remark",
                    },
                  }),
                  _c("p", { staticClass: "bg-line" }),
                  _c(
                    "div",
                    { staticClass: "img-upload" },
                    [
                      _c("common-upload", {
                        ref: "offlineUpload_2",
                        attrs: {
                          file: _vm.facilityForm.attachementModelList,
                          "enable-watermark": false,
                          "enable-album": true,
                          "enable-edit": false,
                        },
                        on: {
                          "update:file": function ($event) {
                            return _vm.$set(
                              _vm.facilityForm,
                              "attachementModelList",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "button-div" },
        [
          _c(
            "van-button",
            { attrs: { color: "#3366FE" }, on: { click: _vm.submitForm } },
            [_c("span", { staticClass: "button-text" }, [_vm._v("提交")])]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }