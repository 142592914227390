var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("van-nav-bar", {
        staticClass: "nav nav-header-home",
        attrs: { fixed: "", "z-index": "999", placeholder: true },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("span", { staticClass: "nav-title-text" }, [
                  _vm._v("设施/设备台账"),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "left",
            fn: function () {
              return [
                _c("div", {
                  staticClass: "icon-back",
                  on: { click: _vm.toBack },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("p", { staticClass: "bg-line" }),
      _c(
        "van-tabs",
        {
          attrs: {
            color: "#3366FE",
            "title-inactive-color": "#7C7F8A",
            "title-active-color": "#3366FE",
            "line-height": "2px",
          },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        [
          _c(
            "van-tab",
            {
              staticClass: "base",
              attrs: {
                "title-class": "tabs-title",
                title: "基本信息",
                name: "base",
              },
            },
            [
              _c(
                "van-cell-group",
                [
                  _c("van-field", {
                    attrs: { readonly: "", required: "", label: "所在项目" },
                    model: {
                      value: _vm.formData.projectName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "projectName", $$v)
                      },
                      expression: "formData.projectName",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      readonly: "",
                      required: "",
                      label: "所在组团",
                      value:
                        _vm.formData.groupName || _vm.formData.projectGroupName,
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      readonly: "",
                      required: "",
                      label: _vm.typeName + "分类",
                    },
                    model: {
                      value: _vm.formData.categoryName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "categoryName", $$v)
                      },
                      expression: "formData.categoryName",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      readonly: "",
                      required: "",
                      label: _vm.typeName + "名称",
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      readonly: "",
                      required: "",
                      label: _vm.typeName + "编码",
                    },
                    model: {
                      value: _vm.formData.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "code", $$v)
                      },
                      expression: "formData.code",
                    },
                  }),
                  _c("van-field", {
                    attrs: { readonly: "", required: "", label: "接管日期" },
                    model: {
                      value: _vm.formData.takeoverTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "takeoverTime", $$v)
                      },
                      expression: "formData.takeoverTime",
                    },
                  }),
                  _vm.sizeCode == 2 || _vm.sizeCode == 3
                    ? _c("van-field", {
                        attrs: {
                          readonly: "",
                          required: "",
                          label: "设备品牌",
                        },
                        model: {
                          value: _vm.formData.brandName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "brandName", $$v)
                          },
                          expression: "formData.brandName",
                        },
                      })
                    : _vm._e(),
                  _vm.sizeCode == 4 || _vm.sizeCode == 2
                    ? _c("van-field", {
                        attrs: {
                          readonly: "",
                          required: "",
                          label: _vm.typeName + "数量",
                        },
                        model: {
                          value: _vm.formData.deviceNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "deviceNum", $$v)
                          },
                          expression: "formData.deviceNum",
                        },
                      })
                    : _vm._e(),
                  _vm.sizeCode == 3
                    ? _c("van-field", {
                        attrs: {
                          readonly: "",
                          required: "",
                          label: "型号或规格",
                        },
                        model: {
                          value: _vm.formData.modelSpec,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "modelSpec", $$v)
                          },
                          expression: "formData.modelSpec",
                        },
                      })
                    : _vm._e(),
                  _vm.sizeCode == 2 || _vm.sizeCode == 3
                    ? _c("van-field", {
                        attrs: {
                          readonly: "",
                          required: "",
                          label: "维保频次",
                        },
                        model: {
                          value: _vm.formData.maintenanceTimeName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "maintenanceTimeName", $$v)
                          },
                          expression: "formData.maintenanceTimeName",
                        },
                      })
                    : _vm._e(),
                  _vm.sizeCode == 3
                    ? _c("van-field", {
                        attrs: {
                          readonly: "",
                          required: "",
                          label: "出厂日期",
                        },
                        model: {
                          value: _vm.formData.pdDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "pdDate", $$v)
                          },
                          expression: "formData.pdDate",
                        },
                      })
                    : _vm._e(),
                  _vm.sizeCode == 3
                    ? _c("van-field", {
                        attrs: {
                          readonly: "",
                          required: "",
                          label: "合同质保到期日",
                        },
                        model: {
                          value: _vm.formData.expiryDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "expiryDate", $$v)
                          },
                          expression: "formData.expiryDate",
                        },
                      })
                    : _vm._e(),
                  _vm.sizeCode == 1 || _vm.sizeCode == 3
                    ? _c("van-field", {
                        attrs: {
                          readonly: "",
                          required: "",
                          label: "是否监控",
                          value: _vm.formData.isMonitor == 1 ? "是" : "否",
                        },
                      })
                    : _vm._e(),
                  _vm.sizeCode == 3
                    ? [
                        _c("van-field", {
                          attrs: {
                            readonly: "",
                            required: "",
                            label: "设备价值(元)",
                          },
                          model: {
                            value: _vm.formData.deviceAmount,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "deviceAmount", $$v)
                            },
                            expression: "formData.deviceAmount",
                          },
                        }),
                        _c("van-field", {
                          attrs: {
                            readonly: "",
                            required: "",
                            label: "检验周期(年)",
                          },
                          model: {
                            value: _vm.formData.checkoutTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "checkoutTime", $$v)
                            },
                            expression: "formData.checkoutTime",
                          },
                        }),
                        _c("van-field", {
                          attrs: {
                            readonly: "",
                            required: "",
                            label: "上次检验日期",
                          },
                          model: {
                            value: _vm.formData.lastCheckoutTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "lastCheckoutTime", $$v)
                            },
                            expression: "formData.lastCheckoutTime",
                          },
                        }),
                        _vm._l(_vm.formData.extras, function (item, index) {
                          return _c("van-field", {
                            key: index,
                            attrs: {
                              readonly: "",
                              required: index === 0,
                              label: item.name,
                            },
                            model: {
                              value: item.model,
                              callback: function ($$v) {
                                _vm.$set(item, "model", $$v)
                              },
                              expression: "item.model",
                            },
                          })
                        }),
                      ]
                    : _vm._e(),
                  _c("p", { staticClass: "bg-line" }),
                  _c("van-field", {
                    staticClass: "form-textarea",
                    attrs: {
                      readonly: "",
                      label: _vm.typeName + "位置",
                      rows: "2",
                      type: "textarea",
                      required: "",
                    },
                    model: {
                      value: _vm.formData.location,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "location", $$v)
                      },
                      expression: "formData.location",
                    },
                  }),
                  _c("p", { staticClass: "bg-line" }),
                  _c("van-field", {
                    staticClass: "form-textarea",
                    attrs: {
                      readonly: "",
                      label: _vm.typeName + "说明",
                      rows: "2",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.formData.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "remark", $$v)
                      },
                      expression: "formData.remark",
                    },
                  }),
                  _vm.formData.attachementModelList &&
                  _vm.formData.attachementModelList.length
                    ? _c("div", [
                        _c("p", { staticClass: "bg-line" }),
                        _c(
                          "div",
                          { staticClass: "img-div" },
                          [
                            _vm._l(_vm.imageArr, function (item, index) {
                              return _c("van-image", {
                                key: index,
                                attrs: {
                                  readonly: "",
                                  radius: "2vw",
                                  width: "12vw",
                                  height: "12vw",
                                  src: item.url,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.imgPreview(index)
                                  },
                                },
                              })
                            }),
                            _vm._l(_vm.docxArr, function (docItem, docIndex) {
                              return _c("div", { key: "doc" + docIndex }, [
                                _c("img", {
                                  staticClass:
                                    "w-12px h-13px align-middle mr-6px",
                                  attrs: {
                                    src: require("@/asset/QRCode/paperClip.svg"),
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticClass: "text-14px align-middle" },
                                  [_vm._v(_vm._s(docItem.name))]
                                ),
                              ])
                            }),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "van-tab",
            {
              attrs: {
                title: "巡检记录(" + _vm.checkDataTotal + ")",
                name: "check",
                "title-class": "tabs-title-change",
              },
            },
            [
              _c("check-list", {
                attrs: { data: _vm.checkData, finish: _vm.checkFinish },
                on: { page: _vm.checkPage },
              }),
            ],
            1
          ),
          _c(
            "van-tab",
            {
              attrs: {
                title: "保养记录(" + _vm.holdDataTotal + ")",
                name: "hold",
                "title-class": "tabs-title-change",
              },
            },
            [
              _c("hold-list", {
                attrs: { data: _vm.holdData, finish: _vm.checkFinish },
                on: { page: _vm.holdPage },
              }),
            ],
            1
          ),
          _c(
            "van-tab",
            {
              attrs: {
                title: "维修记录(" + _vm.repairDataTotal + ")",
                name: "repair",
                "title-class": "tabs-title-change",
              },
            },
            [
              _c("repair-list", {
                attrs: { data: _vm.repairData, finish: _vm.repairFinish },
                on: { page: _vm.repairPage },
              }),
            ],
            1
          ),
          _c(
            "van-tab",
            {
              attrs: {
                title: "变更记录(" + _vm.changeList.length + ")",
                name: "change",
                "title-class": "tabs-title-change",
              },
            },
            [
              _c(
                "van-steps",
                {
                  staticClass: "stpe-component",
                  attrs: { direction: "vertical", "active-color": "#969799" },
                },
                _vm._l(_vm.changeList, function (item, index) {
                  return _c("van-step", { key: index }, [
                    _c(
                      "p",
                      { staticClass: "text-14px text-[#8B8F9A] pt-4px" },
                      [_vm._v(_vm._s(item.lastUpdateDate))]
                    ),
                    _c("span", { staticClass: "text-16px text-[#222222]" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("calcTypeText")(item.operType)) +
                          " "
                      ),
                    ]),
                    item.operType === "1" || item.operType === "2"
                      ? _c(
                          "span",
                          { staticClass: "text-16px text-[#222222]" },
                          [_vm._v(" " + _vm._s(`【${item.createName}】`))]
                        )
                      : _vm._e(),
                    item.operType === "D1" ||
                    item.operType === "D2" ||
                    item.operType === "D3" ||
                    item.operType === "D4" ||
                    item.operType === "D5"
                      ? _c(
                          "span",
                          { staticClass: "text-16px text-[#222222]" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  `${item.operTypeAction}状态【${item.createName}】`
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    item.operType === "3"
                      ? _c("span", [
                          _c(
                            "span",
                            { staticClass: "text-16px text-[#222222]" },
                            [_vm._v(_vm._s(item.content))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "text-16px text-[#222222]",
                              staticStyle: { "margin-left": "-5px" },
                            },
                            [_vm._v(_vm._s(`【${item.createName}】`))]
                          ),
                        ])
                      : _vm._e(),
                  ])
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }