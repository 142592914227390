var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("van-nav-bar", {
        staticClass: "nav nav-header-home",
        attrs: { fixed: "", "z-index": "999", placeholder: true },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("span", { staticClass: "nav-title-text" }, [
                  _vm._v("设备数据"),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "left",
            fn: function () {
              return [
                _c("div", {
                  staticClass: "icon-back",
                  on: { click: _vm.toBack },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "div-center" },
        [
          _c("lottie", {
            ref: "lottie",
            staticClass: "scan-img",
            attrs: { height: 300, options: _vm.logoDefaultOptions },
          }),
          _c("p", { staticClass: "hint" }, [
            _vm._v("请扫码登记或查看设施/设备数据"),
          ]),
          _c(
            "van-button",
            { attrs: { color: "#3366FE" }, on: { click: _vm.jumpScan } },
            [
              _c("img", {
                staticClass: "QRCode-icon",
                attrs: { src: require("@/asset/QRCode/scan-icon.svg") },
              }),
              _c("span", { staticClass: "button-text" }, [_vm._v("扫一扫")]),
            ]
          ),
          _c(
            "van-button",
            { attrs: { color: "#3366FE" }, on: { click: _vm.download } },
            [_c("span", { staticClass: "button-text" }, [_vm._v("下载")])]
          ),
          _c(
            "van-button",
            { attrs: { color: "#3366FE" }, on: { click: _vm.upload } },
            [_c("span", { staticClass: "button-text" }, [_vm._v("上传")])]
          ),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          staticClass: "circle-wrapper",
          model: {
            value: _vm.downloadShow,
            callback: function ($$v) {
              _vm.downloadShow = $$v
            },
            expression: "downloadShow",
          },
        },
        [
          _c("p", { staticClass: "title" }, [
            _vm._v("正在同步数据，请不要离开!"),
          ]),
          _c("van-circle", {
            attrs: {
              rate: 30,
              speed: 100,
              color: "#3366FE",
              "layer-color": "#EBECF0",
              "stroke-width": "70",
              text: "下载数据中",
            },
            model: {
              value: _vm.donloadCircle,
              callback: function ($$v) {
                _vm.donloadCircle = $$v
              },
              expression: "donloadCircle",
            },
          }),
          _c("p", { staticClass: "desc" }, [_vm._v("正在下载数据…")]),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          staticClass: "circle-wrapper success-wrapper",
          model: {
            value: _vm.downloadSuccessShow,
            callback: function ($$v) {
              _vm.downloadSuccessShow = $$v
            },
            expression: "downloadSuccessShow",
          },
        },
        [
          _c("p", { staticClass: "title" }, [_vm._v("恭喜您，下载完成!!")]),
          _c("div", { staticClass: "success-icon" }),
          _c("p", { staticClass: "desc" }, [_vm._v("下载完成")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }