var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "offline-list-wrapper" },
    [
      _c("my-nav", { attrs: { title: "待上传离线设施设备数据" } }, [
        _c(
          "p",
          {
            staticClass: "submit-btn",
            class: { disabled: _vm.submitLoading },
            attrs: { slot: "right" },
            on: {
              click: function ($event) {
                return _vm.handleSubmit(_vm.list)
              },
            },
            slot: "right",
          },
          [_vm._v("全部提交")]
        ),
      ]),
      _vm.list.length > 0
        ? _c(
            "div",
            { staticClass: "list-wrapper" },
            _vm._l(_vm.list, function (item, index) {
              return _c("div", { key: index }, [
                _c("div", { staticClass: "order-cell" }, [
                  _c("div", { staticClass: "order-cell-title" }, [
                    _c("div", { staticClass: "flex-box" }, [
                      _c("div", { staticClass: "order-number" }, [
                        _vm._v(_vm._s(item.qrCode)),
                      ]),
                    ]),
                    _c("div", { staticClass: "btn-wrapper" }, [
                      _c(
                        "p",
                        {
                          staticClass: "btn del",
                          class: { disabled: _vm.submitLoading },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(item)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                      !item.repeatSubmit
                        ? _c(
                            "p",
                            {
                              staticClass: "btn",
                              class: { disabled: _vm.submitLoading },
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmit([item])
                                },
                              },
                            },
                            [_vm._v("提交")]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "order-body" },
                    [
                      _c(
                        "van-row",
                        { staticClass: "order-body-cell" },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [_vm._v("所在项目:")]
                          ),
                          _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 18 } },
                            [_c("span", [_vm._v(_vm._s(item.projectName))])]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        { staticClass: "order-body-cell" },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [_vm._v("所在组团:")]
                          ),
                          _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 16 } },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.projectGroupName)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        { staticClass: "order-body-cell" },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [
                              _vm._v(
                                _vm._s(
                                  item.createType === "equip" ? "设备" : "设施"
                                ) + "分类:"
                              ),
                            ]
                          ),
                          _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 16 } },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.categoryFullName)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        { staticClass: "order-body-cell" },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [
                              _vm._v(
                                _vm._s(
                                  item.createType === "equip" ? "设备" : "设施"
                                ) + "名称:"
                              ),
                            ]
                          ),
                          _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 18 } },
                            [_c("span", [_vm._v(_vm._s(item.name))])]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        { staticClass: "order-body-cell" },
                        [
                          _c(
                            "van-col",
                            { staticClass: "sub-key", attrs: { span: 6 } },
                            [
                              _vm._v(
                                _vm._s(
                                  item.createType === "equip" ? "设备" : "设施"
                                ) + "位置:"
                              ),
                            ]
                          ),
                          _c(
                            "van-col",
                            { staticClass: "sub-value", attrs: { span: 18 } },
                            [_c("span", [_vm._v(_vm._s(item.location))])]
                          ),
                        ],
                        1
                      ),
                      item.repeatSubmit
                        ? _c("div", { staticClass: "repeatSubmit" }, [
                            _vm._v("重复登记"),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ])
            }),
            0
          )
        : _c("no-data", { attrs: { tex: "暂无待上传离线设备数据" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }