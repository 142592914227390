<template>
  <!-- 未登记 -->
  <div>
    <van-nav-bar class="nav nav-header-home" fixed z-index="999" :placeholder="true">
      <template v-slot:title>
        <span class="nav-title-text">设施/设备台账</span>
      </template>
      <template v-slot:left>
        <div class="icon-back" @click="toBack"></div>
      </template>
    </van-nav-bar>
    <van-tabs v-model="active" color="#3366FE" title-inactive-color="#7C7F8A"
      title-active-color="#3366FE" line-height="2px">
      <van-tab title-class="tabs-title" title="设备" name="equip">
        <van-cell-group>
          <van-field required label="所在项目" readonly @click="projectShowM('equip')" v-model="equipForm.projectName"
            placeholder="请选择" />
          <!--所在项目弹窗 @click="projectShowM('equip')" -->
          <van-field required label="所在组团" readonly v-model="equipForm.projectGroupName"
            @click="groupModal('equip')" placeholder="请选择" />
          <van-popup v-model="showGruop" position="bottom" :close-on-click-overlay='false'>
            <van-picker show-toolbar :columns="groupOptions" value-key="full_name"
              @confirm="groupOK($event,'equipForm')" @cancel="showGruop = false" />
              <van-row class="col-inp">
                <van-col span="10"><van-field v-model="equipForm.groupCode" placeholder="请输入组团代码"></van-field></van-col>
                <van-col span="9"><van-field v-model="equipForm.groupName" placeholder="请输入组团名称"></van-field></van-col>
                <van-col span="5"><van-button size="small" type="primary" @click="createGroups('equipForm')">手动创建</van-button></van-col>
              </van-row>
          </van-popup>
          <van-field required label="设备分类" v-model="equipForm.categoryName" placeholder="请选择"
            @click="equipShowM" />
          <van-field required label="设备名称" v-model="equipForm.name" placeholder="请输入" />
          <van-field required label="设备编码" placeholder="请输入" :disabled="!isOffline" v-model="equipForm.code" />
          <van-field required label="接管日期" placeholder="请选择" @click="showDate = true"
            v-model="equipForm.takeoverTime" readonly clickable />
          <van-popup v-model="showDate" position="bottom" :close-on-click-overlay='false'>
            <van-datetime-picker :min-date="minDate" type="date" :max-date="maxDate" v-model="maxCurrentDate"
              @confirm="dateOK($event,'equipForm')" @cancel="showDate = false" />
          </van-popup>
          <van-field required label="设备品牌" v-model="equipForm.brandName" @click="showBrand = true"
            placeholder="请选择" readonly clickable />
          <van-popup v-model="showBrand" position="bottom" :close-on-click-overlay='false'>
            <van-picker show-toolbar :columns="brandOptions" value-key="makeName" @confirm="BrandOK"
              @cancel="showBrand = false" />
            <van-field v-model="equipForm.brandName" placeholder="手动输入"><template #button>
                <van-button size="small" type="primary" @click="creatBrand">手动创建
                </van-button>
              </template></van-field>
          </van-popup>
          <van-field placeholder="请输入" v-model="equipForm.deviceNum" v-if="calcFormType === 1"
            required label="设备数量" type="digit" />
          <van-field v-model="equipForm.modelSpec" v-if="calcFormType === 2" required label="型号或规格"
            placeholder="请输入" />
          <van-field required label="维保频次" @click="showCount = true" placeholder="请选择"
            v-model="equipForm.maintenanceTimeName" readonly clickable />
          <van-popup v-model="showCount" position="bottom" :close-on-click-overlay='false'>
            <van-picker show-toolbar :columns="countOptions" value-key="name" @confirm="countOK"
              @cancel="showCount = false" />
          </van-popup>
          <template v-if="calcFormType === 2">
            <van-field v-model="equipForm.pdDate" required label="出厂日期" placeholder="请选择"
              @click="showOutDate = true" readonly clickable />
            <van-popup v-model="showOutDate" position="bottom" :close-on-click-overlay='false'>
              <van-datetime-picker :min-date="minDate" type="date" @confirm="outDateOK" @cancel="showOutDate = false"
                v-model="currentDate" />
            </van-popup>
            <van-field v-model="equipForm.expiryDate" required label="合同质保到期日" placeholder="请选择"
              @click="showContDate = true" readonly clickable />
            <van-popup v-model="showContDate" position="bottom" :close-on-click-overlay='false'>
              <van-datetime-picker :min-date="minDate" type="date" @confirm="contDateOK" @cancel="showContDate = false"
                v-model="currentDate" />
            </van-popup>
            <van-field v-model="equipForm.isMonitorName" required label="是否监控"
              @click="showMonitor = true" readonly clickable />
            <van-popup v-model="showMonitor" position="bottom" :close-on-click-overlay='false'>
              <van-picker show-toolbar :columns="isMonitorOptions" value-key="label"
                @confirm="monitorOK($event,'equipForm')" @cancel="showMonitor = false" />
            </van-popup>
            <van-field v-model="equipForm.deviceAmount" required type="number" label="设备价值(元)"
              placeholder="请输入" />
            <van-field v-model="equipForm.checkoutTime" required type="digit" label="检验周期(年)"
              placeholder="请输入" />
            <van-field v-model="equipForm.lastCheckoutTime" required label="上次检验日期"
              @click="showCheckDate = true" placeholder="请选择" readonly clickable />
            <van-popup v-model="showCheckDate" position="bottom" :close-on-click-overlay='false'>
              <van-datetime-picker :min-date="minDate" type="date" :max-date="maxDate" @confirm="checkDateOK"
                @cancel="showCheckDate = false" v-model="maxCurrentDate" />
            </van-popup>
            <van-field v-model="equipForm.extras[index].model"
              v-for="(item, index) in equipForm.extras" :key="index" :label="item.name"
              :required="index === 0" @click="showExtrasM(index)" placeholder="请选择" readonly
              clickable />
            <van-popup v-model="showExtras" position="bottom" :close-on-click-overlay='false'>
              <van-picker show-toolbar :columns="
                  equipForm.extras[extrasIndex].value
                    ? (equipForm.extras[extrasIndex].value || []).split(',')
                    : []
                " @confirm="extrasOK" @cancel="showExtras = false" />
              <van-field v-model="equipForm.extras[extrasIndex].model" placeholder="手动输入"><template
                  #button>
                  <van-button size="small" type="primary" @click="showExtras = false">手动创建
                  </van-button>
                </template></van-field>
            </van-popup>
          </template>
          <p class="bg-line"></p>
          <van-field label="设备位置" placeholder="请输入" v-model="equipForm.location" rows="2"
            class="form-textarea" type="textarea" required />
          <p class="bg-line"></p>
          <van-field label="设备说明" placeholder="请输入" v-model="equipForm.remark" rows="2"
            class="form-textarea" type="textarea" />
          <p class="bg-line"></p>
          <div class="img-upload">
            <common-upload
              ref="offlineUpload_1"
              :file.sync="equipForm.attachementModelList"
              :enable-watermark="false"
              :enable-album="true"
              :enable-edit="false"
            ></common-upload>
          </div>
        </van-cell-group>
      </van-tab>
      <van-tab title-class="tabs-title" title="设施" name="facility">
        <van-cell-group>
          <van-field required label="所在项目" readonly @click="projectShowM('facility')" v-model="facilityForm.projectName"
            placeholder="请选择" />
          <!--所在项目弹窗 @click="projectShowM('facility')" -->
          <van-field required label="所在组团" v-model="facilityForm.projectGroupName"
            @click="groupModal('facility')" placeholder="请选择" readonly />
          <van-popup v-model="showGruop" position="bottom" :close-on-click-overlay='false'>
            <van-picker show-toolbar :columns="groupOptionsF" value-key="full_name"
              @confirm="groupOK($event,'facilityForm')" @cancel="showGruop = false" />
              <van-row class="col-inp">
                <van-col span="10"><van-field v-model="facilityForm.groupCode" placeholder="请输入组团代码"></van-field></van-col>
                <van-col span="9"><van-field v-model="facilityForm.groupName" placeholder="请输入组团名称"></van-field></van-col>
                <van-col span="5"><van-button size="small" type="primary" @click="createGroups('facilityForm')">手动创建</van-button></van-col>
              </van-row>
          </van-popup>
          <van-field required label="设施分类" v-model="facilityForm.categoryName" placeholder="请选择"
            @click="facilityShowM" />
          <van-field required label="设施名称" v-model="facilityForm.name" placeholder="请输入" />
          <van-field required label="设施编码" v-model="facilityForm.code" :disabled="!isOffline" placeholder="请输入" />
          <van-field required label="接管日期" placeholder="请选择" @click="showDate = true"
            v-model="facilityForm.takeoverTime" readonly clickable />
          <van-popup v-model="showDate" position="bottom" :close-on-click-overlay='false'>
            <van-datetime-picker :min-date="minDate" type="date" :max-date="maxDate" v-model="maxCurrentDate"
              @confirm="dateOK($event,'facilityForm')" @cancel="showDate = false" />
          </van-popup>
          <van-field required label="设施数量" v-if="facilityType===1" placeholder="请输入"
            v-model="facilityForm.deviceNum" />
          <van-field v-model="facilityForm.isMonitorName" v-if="facilityType===2" required
            label="是否监控" @click="showMonitor = true" readonly clickable />
          <van-popup v-model="showMonitor" position="bottom" :close-on-click-overlay='false'>
            <van-picker show-toolbar :columns="isMonitorOptions" value-key="label"
              @confirm="monitorOK($event,'facilityForm')" @cancel="showMonitor = false" />
          </van-popup>
          <p class="bg-line"></p>
          <van-field label="设施位置" rows="2" placeholder="请输入" class="form-textarea" type="textarea"
            required v-model="facilityForm.location" />
          <p class="bg-line"></p>
          <van-field label="设施说明" rows="2" placeholder="请输入" class="form-textarea" type="textarea"
            v-model="facilityForm.remark" />
          <p class="bg-line"></p>
          <div class="img-upload">
            <common-upload
              ref="offlineUpload_2"
              :file.sync="facilityForm.attachementModelList"
              :enable-watermark="false"
              :enable-album="true"
              :enable-edit="false"
            ></common-upload>
          </div>
        </van-cell-group>
      </van-tab>
    </van-tabs>
    <div class="button-div">
      <van-button color="#3366FE" @click="submitForm">
        <span class="button-text">提交</span>
      </van-button>
    </div>
  </div>
</template>

<script>
import api from '@/api/index'
import {
  getPreMadeQRCodeHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/preMadeQRCodeOfflineApiHandler.js'
import {
  setTobeUploadEquipment
} from '@/plugins/offlineMixin/offlineApiHandlers/tobeUploadEquipmentOfflineApiHandler.js'
import {
  uploadPhotoByImgKeys
} from '@/plugins/offlineMixin/utils/offlineImgTool'
import mobile from '@/utils/mobile'

export default {
  name: 'QRCodeNoRegister',
  mounted () {
    // 设备
    this.$EB.$on(this.$EBT.EquipClass, (item) => {
      this.equipForm.categoryName = item.codeName
      this.equipForm.categoryCode = item.code
      this.equipForm.manageGrainSizeCode = item.manageGrainSizeCode
      this.equipForm.categoryFullName = item.fullName
      this.renderCode('equipForm')
      this.renderBrand(item) // 选择分类后
    })
    // 设施
    this.$EB.$on(this.$EBT.FacilityClass, (item) => {
      this.facilityForm.categoryName = item.codeName
      this.facilityForm.categoryCode = item.code
      this.facilityForm.manageGrainSizeCode = item.manageGrainSizeCode
      this.facilityForm.categoryFullName = item.fullName
      this.renderCode('facilityForm')
      this.renderBrandF(this.facilityForm.categoryCode, item)
    })
    // 所在项目
    this.$EB.$on(this.$EBT.ProjectList, (item) => {
      if (item.clickType === 'equip') {
        this.equipForm.projectGroupName = undefined
        this.equipForm.projectGroupCode = undefined
        this.equipForm.projectName = item.codeName
        this.equipForm.projectCode = item.code
        this.renderCode('equipForm')
      } else {
        this.facilityForm.projectGroupName = undefined
        this.facilityForm.projectGroupCode = undefined
        this.facilityForm.projectName = item.codeName
        this.facilityForm.projectCode = item.code
        this.renderCode('facilityForm')
      }
    })
    // 所在项目 现在改成不可选  2022/1/7又可以选了
    // this.equipForm.projectName = this.$route.query.projectName
    // this.equipForm.projectCode = this.$route.query.projectCode
    // this.renderGroup(this.equipForm.projectCode)
    // this.facilityForm.projectName = this.$route.query.projectName
    // this.facilityForm.projectCode = this.$route.query.projectCode
    // this.renderGroupF(this.facilityForm.projectCode)
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    calcFormType () {
      // 返回表单类型
      return this.equipForm.manageGrainSizeCode === 'manageGrainSize3' ? 2 : 1
    },
    facilityType () {
      return this.facilityForm.manageGrainSizeCode === 'manageGrainSize1'
        ? 2
        : 1
    }
  },
  data () {
    return {
      equipForm: {
        isEnterFM: 1,
        categoryCode: undefined, // 设备分类code
        categoryName: undefined,
        manageGrainSizeCode: undefined, // 管理粒度
        name: undefined, // 设备名称
        code: undefined, // 设备编码
        projectCode: undefined, // 所在项目
        projectName: undefined,
        projectGroupCode: undefined, // 所在组团
        projectGroupName: undefined,
        takeoverTime: null, // 接管日期
        brandCode: undefined, // 设备品牌code
        brandName: undefined,
        deviceNum: 1, // 设备数量
        modelSpec: undefined, // 型号或规格
        pdDate: null, // 出厂日期
        expiryDate: null, // 合同质保到期日
        isMonitor: 0, // 是否监控
        isMonitorName: '否',
        deviceAmount: undefined, // 设备价值
        checkoutTime: undefined, // 检验周期
        lastCheckoutTime: null, // 上次检验日期
        extras: [
          { name: '主要参数1', value: undefined },
          { name: '主要参数2', value: undefined },
          { name: '主要参数3', value: undefined }
        ], // 主要参数
        maintenanceTimeCode: undefined, // 维保频次
        maintenanceTimeName: undefined,
        location: undefined, // 设备位置
        remark: undefined, // 设施说明
        commitType: 1, // 1新增 2修改
        attachementModelList: [],
        qrCode: undefined,
        groupCode: undefined, // 组团代码
        groupName: undefined
      },
      facilityForm: {
        isEnterFM: 1,
        categoryCode: undefined, // 分类
        categoryName: undefined,
        name: undefined, // 设施名称
        code: undefined, // 编码
        projectCode: undefined, // 所在项目
        projectName: undefined,
        projectGroupCode: undefined, // 所在组团
        projectGroupName: undefined,
        takeoverTime: null, // 接管日期
        deviceNum: 1, // 设施数量
        isMonitor: 0, // 是否监控
        isMonitorName: '否',
        location: undefined, // 设备位置
        manageGrainSizeCode: undefined, // 管理粒度
        remark: undefined, // 设施说明
        attachementModelList: [], // 照片
        commitType: '1',
        qrCode: undefined,
        groupCode: undefined, // 组团代码
        groupName: undefined
      },
      equipShow: false,
      facilityShow: false,
      active: 'equip',
      maxDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 1
      ),
      minDate: new Date(1900, 0, 1),
      maxCurrentDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 1
      ),
      currentDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      showDate: false, // 日期
      showOutDate: false, // 出厂日期
      showContDate: false, // 合同质保到期日
      showCheckDate: false, // 上次检验日期
      showBrand: false, // 设备品牌
      projectShow: false, // 项目
      projectShowF: false,
      brandOptions: [],
      showCount: false, // 頻次
      countOptions: [],
      showExtras: false, // 主要参数弹窗
      showGruop: false, // 组团
      groupOptions: [],
      groupOptionsF: [],
      isMonitorOptions: [
        { label: '是', value: 1 },
        { label: '否', value: 0 }
      ], // 监控
      showMonitor: false,
      extrasIndex: 0, // 主要参数弹窗索引
      // 弹窗的下拉
      equipList: [],
      facilityList: [],
      projectList: [],
      equipValidFlag: true,
      submitLoading: false
    }
  },
  async activated () {
    // 记得传qrcode
    console.log(this.$storage.get('qrCode'))
    this.equipForm.qrCode = this.$storage.get('qrCode')
    this.equipForm.createType = this.active
    this.facilityForm.qrCode = this.$storage.get('qrCode')
    this.facilityForm.createType = this.active
    this.renderCount()
    // 所在项目 如果有就赋值
    // if (this.$route.query.projectCode) {
    //   this.equipForm.projectName = this.$route.query.projectName
    //   this.equipForm.projectCode = this.$route.query.projectCode
    //   this.renderGroup(this.equipForm.projectCode)
    //   this.facilityForm.projectName = this.$route.query.projectName
    //   this.facilityForm.projectCode = this.$route.query.projectCode
    //   this.renderGroupF(this.facilityForm.projectCode)
    // }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'QRCodeScan') {
      // 如果返回到首页就清空缓存
      Object.assign(this.equipForm, this.$options.data().equipForm)
      Object.assign(this.facilityForm, this.$options.data().facilityForm)
      // 清空图片
      this.$refs.offlineUpload_1 && this.$refs.offlineUpload_1.resetData()
      this.$refs.offlineUpload_2 && this.$refs.offlineUpload_2.resetData()
    }
    next()
  },
  methods: {
    toBack () {
      this.$router.back()
    },
    equipShowM () {
      this.$router.push({ name: 'equipList' })
    },
    // 项目下拉
    projectShowM (type) {
      this.$router.push({ name: 'projectList', query: { type: type } })
    },
    facilityShowM () {
      this.$router.push({ name: 'facilityList' })
    },
    // 组团
    groupModal (value) {
      if (value === 'equip') {
        if (!this.equipForm.projectCode) return
        this.equipForm.groupCode = undefined
        this.equipForm.groupName = undefined
        this.showGruop = true
        this.renderGroup(this.equipForm.projectCode)
      }
      if (value === 'facility') {
        if (!this.facilityForm.projectCode) return
        this.facilityForm.groupCode = undefined
        this.facilityForm.groupName = undefined
        this.showGruop = true
        this.renderGroupF(this.facilityForm.projectCode)
      }
    },
    // 频次字典
    async renderCount () {
      if (this.isOffline) {
        const data = await this.$http.get(api.dictionaryitem, {
          dic_code: 'maintenanceTime'
        })
        this.countOptions = data
      } else {
        const preMadeQRCodeOfflineData = await getPreMadeQRCodeHandler()
        preMadeQRCodeOfflineData && (this.countOptions = preMadeQRCodeOfflineData.maintenanceTimes)
      }
    },
    // 设备品牌
    async renderBrand (equip) {
      const code = equip.code
      let data
      if (this.isOffline) {
        data = await this.$http.get(api.equipBrand + '/' + code)
        this.equipForm.name = data.name
      } else {
        data = equip
        this.equipForm.name = data.codeName
      }
      if (!data) return
      // this.equipForm.code = data.code//不再是根据品牌生成 根据组团、设备、项目code拼接
      this.brandOptions = data.classMake || []
      // bugfix 主要参数
      this.equipForm.extras = data.extras || [
        { name: '主要参数1', value: undefined },
        { name: '主要参数2', value: undefined },
        { name: '主要参数3', value: undefined }
      ]
      console.log('branddata', data)
      console.log('form', this.equipForm)
    },
    // 设施品牌
    async renderBrandF (code, facility) {
      if (this.isOffline) {
        const data = await this.$http.get(api.facilityBrand + '/' + code)
        // this.facilityForm.code = data.code
        this.facilityForm.name = data.name
      } else {
        this.facilityForm.name = facility.codeName
      }
    },
    // 所在组团
    async renderGroup (code) {
      if (this.isOffline) {
        const data = await this.$http.get(api.equipGroup, { projectCode: code })
        this.groupOptions = data
      } else {
        const preMadeQRCodeOfflineData = await getPreMadeQRCodeHandler()
        if (preMadeQRCodeOfflineData) {
          const groupOptions = preMadeQRCodeOfflineData.projectGroups.filter(item => item.projectCode === code) || []
          this.groupOptions = groupOptions.map((item) => {
            return {
              code: item.code,
              full_code: item.code,
              full_name: item.name,
              groupCode: item.groupCode
            }
          })
        }
      }
      console.log('this.groupOptions: ', this.groupOptions)
    },
    async renderGroupF (code) {
      if (this.isOffline) {
        const data = await this.$http.get(api.equipGroup, { projectCode: code })
        this.groupOptionsF = data
      } else {
        const preMadeQRCodeOfflineData = await getPreMadeQRCodeHandler()
        if (preMadeQRCodeOfflineData) {
          const groupOptions = preMadeQRCodeOfflineData.projectGroups.filter(item => item.projectCode === code) || []
          this.groupOptionsF = groupOptions.map((item) => {
            return {
              code: item.code,
              full_code: item.code,
              full_name: item.name,
              groupCode: item.groupCode
            }
          })
        }
      }
      console.log('this.groupOptionsF: ', this.groupOptionsF)
    },
    // 日历
    dateOK (value, type) {
      if (value > this.maxDate) return
      this[`${type}`].takeoverTime = this.timeFormat(value)
      this.showDate = false
    },
    // 出厂日期
    outDateOK (value) {
      this.equipForm.pdDate = this.timeFormat(value)
      this.showOutDate = false
    },
    // 合同质保到期日
    contDateOK (value) {
      this.equipForm.expiryDate = this.timeFormat(value)
      this.showContDate = false
    },
    // 上次检验日期
    checkDateOK (value) {
      if (value > this.maxDate) return
      this.equipForm.lastCheckoutTime = this.timeFormat(value)
      this.showCheckDate = false
    },
    // 主要参数打开
    showExtrasM (i) {
      this.extrasIndex = i
      this.showExtras = true
    },
    // 频次
    countOK (value) {
      this.equipForm.maintenanceTimeName = value.name
      this.equipForm.maintenanceTimeCode = value.code
      this.showCount = false
    },
    // 品牌
    BrandOK (value) {
      this.equipForm.brandCode = value.makeCode
      this.equipForm.brandName = value.makeName
      this.showBrand = false
    },
    // 组团
    groupOK (value, type) {
      this[`${type}`].projectGroupCode = value.full_code
      this[`${type}`].projectGroupName = value.full_name
      this[`${type}`].groupCode = value.groupCode
      this[`${type}`].groupName = value.full_name
      this.renderCode(type)
      this.showGruop = false
    },
    // 监控
    monitorOK (value, type) {
      this[`${type}`].isMonitorName = value.label
      this[`${type}`].isMonitor = value.value
      this.showMonitor = false
    },
    extrasOK (value) {
      this.equipForm.extras[this.extrasIndex].model = value
      this.showExtras = false
    },
    // 提交
    async submitForm () {
      // 根据tabs的值调用接口
      if (this.active === 'equip') {
        if (this.equipValidate()) return
        // 在线提交
        if (this.isOffline) {
          const submitLoading = this.$toast.loading({
            message: '正在提交中...',
            duration: 0,
            forbidClick: true
          })
          try {
            const equipFormCopy = this.lodash.cloneDeep(this.equipForm)
            let offlineImageKeys = null
            if (equipFormCopy.attachementModelList && equipFormCopy.attachementModelList.length > 0) {
              offlineImageKeys = this.lodash.cloneDeep(equipFormCopy.attachementModelList)
              equipFormCopy.attachementModelList = await uploadPhotoByImgKeys(equipFormCopy.attachementModelList)
            }
            /* 因为在离线时上传的图片，所以在线提交设备数据时，要对图片进行处理 */
            // await this.handleAttachmentModelList(equipFormCopy)
            console.log('equipFormCopy: ', equipFormCopy)
            equipFormCopy.source = 'APP'
            // const equipData = await this.$http.post(api.addEquip, equipForm)
            const equipData = await this.$apiOffline({
              api: api.addEquip,
              methods: 'post',
              params: { ...equipFormCopy },
              toastText: '正在提交...'
            })

            // 设备编码重复
            if (equipData === 500007) {
              const equipBeforeCode = this.equipForm.code
              this.renderCode('equipForm')
              setTimeout(() => {
                this.$toast(
                  {
                    message: '设备编码「' + equipBeforeCode + '」已被使用,系统已为你自动刷新为「' + this.equipForm.code + '」,请确认无误后再次提交!',
                    duration: 5000
                  }
                )
              }, 500)
              return
            }
            // 二维码重复重复
            if (equipData.result === 500008) {
              this.$toast(
                {
                  message: equipData.message,
                  duration: 5000
                }
              )
              return
            }
            this.$toast('提交成功')
            setTimeout(async () => {
              await mobile.deleteImgsInCache(offlineImageKeys)
              this.$router.back()
            }, 500)
          } catch (error) {
            console.error('error: ', error)
          } finally {
            submitLoading.clear()
          }
        } else {
          // 离线提交到待上传
          console.log('this.equipForm: ', this.equipForm)
          await setTobeUploadEquipment(this.equipForm)
          this.$router.back()
        }
      } else if (this.active === 'facility') {
        // 设施提交
        if (this.facilityValidate()) return
        if (this.isOffline) {
          const submitLoading = this.$toast.loading({
            message: '正在提交中...',
            duration: 0,
            forbidClick: true
          })
          try {
            const facilityForm = this.lodash.cloneDeep(this.facilityForm)
            let offlineImageKeys = null
            if (facilityForm.attachementModelList && facilityForm.attachementModelList.length > 0) {
              offlineImageKeys = this.lodash.cloneDeep(facilityForm.attachementModelList)
              facilityForm.attachementModelList = await uploadPhotoByImgKeys(facilityForm.attachementModelList)
            }
            facilityForm.source = 'APP'
            const facilityData = await this.$apiOffline({
              api: api.addFacility,
              methods: 'post',
              params: { ...facilityForm },
              toastText: '正在提交...'
            })
            console.log('facilityData: ', facilityData)
            // 设备编码重复
            if (facilityData === 500007) {
              const facilityBeforeCode = this.facilityForm.code
              this.renderCode('facilityForm')
              setTimeout(() => {
                this.$toast(
                  {
                    message: '设施编码「' + facilityBeforeCode + '」已被使用,系统已为你自动刷新为「' + this.facilityForm.code + '」,请确认无误后再次提交!',
                    duration: 5000
                  }
                )
              }, 500)
              return
            }
            // 二维码重复重复
            if (facilityData.result === 500008) {
              this.$toast(
                {
                  message: facilityData.message,
                  duration: 5000
                }
              )
              return
            }
            this.$toast('提交成功')
            setTimeout(async () => {
              await mobile.deleteImgsInCache(offlineImageKeys)
              this.$router.back()
            }, 400)
          } catch (error) {
            console.error('error: ', error)
          } finally {
            submitLoading.clear()
          }
        } else {
          console.log('this.facilityForm: ', this.facilityForm)
          await setTobeUploadEquipment(this.facilityForm)
          this.$router.back()
        }
      }
    },
    // 校验
    equipValidate () {
      // 判断管理粒度
      const equip = this.equipForm
      let equipValue = [
        { name: 'categoryCode', toast: '请选择设备分类' },
        { name: 'name', toast: '请输入设备名称' },
        { name: 'code', toast: '请输入设备编码' },
        { name: 'projectCode', toast: '请选择所在项目' },
        { name: 'projectGroupName', toast: '请选择所在组团' },
        { name: 'takeoverTime', toast: '请选择接管日期' },
        { name: 'brandCode', toast: '请选择设备品牌' },
        { name: 'maintenanceTimeCode', toast: '请选择维保频次' },
        { name: 'location', toast: '请输入设备位置' }
      ]
      for (let i = 0; i < equipValue.length; i++) {
        if (!equip[`${equipValue[i].name}`]) {
          this.$toast(equipValue[i].toast)
          return true
        }
      }
      if (equip.manageGrainSizeCode === 'manageGrainSize3') {
        let equipValueSize3 = [
          { name: 'modelSpec', toast: '请输入型号或规格' },
          { name: 'pdDate', toast: '请选择出厂日期' },
          { name: 'expiryDate', toast: '请选择合同质保到期日' },
          // { name: 'isMonitor', toast: '请选择是否监控' },
          { name: 'lastCheckoutTime', toast: '请输入上次检验日期' }
        ]
        for (let s = 0; s < equipValueSize3.length; s++) {
          if (!equip[`${equipValueSize3[s].name}`]) {
            this.$toast(equipValueSize3[s].toast)
            return true
          }
        }
        if (!equip.deviceAmount || !equip.deviceAmount > 0) {
          this.$toast('请输入正确的设备价值')
          return true
        }
        if (!equip.checkoutTime || !/(^[1-9]\d*$)/.test(equip.checkoutTime)) {
          this.$toast('请输入检验周期')
          return true
        }
        if (!equip.extras[0].model) {
          this.$toast('请选择主要参数')
          return true
        }
      } else {
        if (!equip.deviceNum || !/(^[1-9]\d*$)/.test(equip.deviceNum)) {
          this.$toast('请输入正确的设备数量')
          return true
        }
      }
    },
    facilityValidate () {
      const facility = this.facilityForm
      let facilityValue = [
        { name: 'categoryCode', toast: '请选择设施分类' },
        { name: 'name', toast: '请输入设施名称' },
        { name: 'code', toast: '请输入设施编码' },
        { name: 'projectCode', toast: '请选择所在项目' },
        { name: 'projectGroupName', toast: '请选择所在组团' },
        { name: 'takeoverTime', toast: '请选择接管日期' },
        { name: 'location', toast: '请输入设备位置' }
      ]
      for (let i = 0; i < facilityValue.length; i++) {
        if (!facility[`${facilityValue[i].name}`]) {
          this.$toast(facilityValue[i].toast)
          return true
        }
      }
      if (facility.manageGrainSizeCode !== 'manageGrainSize1') {
        if (!facility.deviceNum || !/(^[1-9]\d*$)/.test(facility.deviceNum)) {
          this.$toast('请输入正确的设施数量')
          return true
        }
      }
    },
    // 手动创建品牌
    creatBrand () {
      this.showBrand = false
      this.equipForm.brandCode = this.equipForm.brandName
    },
    async createGroups (type) {
      if (!this[`${type}`].groupCode) {
        this.$toast('请输入组团代码')
        return
      }
      if (!this[`${type}`].groupName) {
        this.$toast('请输入组团名称')
        return
      }
      if (this.isOffline) {
        const obj = {
          commitType: 1,
          groupCode: this[`${type}`].groupCode,
          name: this[`${type}`].groupName,
          projectCode: this[`${type}`].projectCode,
          state: '1'
        }
        const data = await this.$http.post(api.createGroup, obj)
        this.renderCode(type)
        // 创建组团时把返回的编码给projectgroupcode
        this[type].projectGroupCode = data
        this[type].projectGroupName = this[type].groupName
      } else {
        let groupCodes = []
        if (type === 'equipForm') groupCodes = this.groupOptions
        if (type === 'facilityForm') groupCodes = this.groupOptionsF
        const hasExistName = groupCodes.find(item => item.full_name === this[type].groupName)
        if (hasExistName) {
          this.$notify({
            type: 'warning',
            message: '组团名称已存在，请调整后重新提交！'
          })
          return
        }
        // 离线创建组团代码
        this[type].projectGroupCode = this[type].groupCode
        this[type].projectGroupName = this[type].groupName
      }
      this.showGruop = false
    },
    // 判断三个值是否都存在 存在掉接口
    async renderCode (type) {
      console.log('renderCode: ', this[`${type}`])
      if (this[`${type}`].categoryCode && this[`${type}`].projectCode && (this[`${type}`].groupCode || this[`${type}`].projectGroupCode)) {
        // 在线
        const params = {
          categoryCode: this[`${type}`].categoryCode,
          projectCode: this[`${type}`].projectCode,
          projectGroupCode: this[`${type}`].groupCode ? this[`${type}`].groupCode : this[`${type}`].projectGroupCode,
          type: type === 'equipForm' ? '3' : '2'
        }
        if (this.isOffline) {
          const data = await this.$http.post(api.getCode, params)
          this[`${type}`].code = data
        } else {
          // 离线根据已选 生成设备编码
          this[`${type}`].code = `${params.projectGroupCode}-${params.categoryCode}-0001`
        }
      } else {
        console.log(this[`${type}`].categoryCode, this[`${type}`].projectCode, this[`${type}`].groupCode)
      }
    },
    // 日期格式化
    timeFormat (time) {
      const year = time.getFullYear()
      const month = time.getMonth() + 1
      const day = time.getDate()
      return (
        year +
        '-' +
        (month > 9 ? month : '0' + month) +
        '-' +
        (day > 9 ? day : '0' + day)
      )
    }
  }
}
</script>

<style lang="less" scoped>
.nav {
  .nav-title-text {
    font-size: 18px;
    font-weight: 601;
    color: #222222;
  }
  .icon-back {
    height: 24px;
    width: 24px;
    background: url("../../asset/icon_back_black@2x.png") no-repeat;
    background-size: cover;
  }
}
/* tab底部颜色的长度 */
::v-deep .van-tabs__line {
  width: 50%;
}
// 灰色分割
.bg-line {
  width: 100%;
  height: 8px;
  background-color: #f2f2f2;
  margin-bottom: 0;
}
.img-upload {
  padding: 16px;
}
// ::v-deep
//   .form-upload
  // .van-icon.van-icon-photograph.van-uploader__upload-icon:before {
  // content: "";
  // background-image: url("../../asset/icon_add_grey@2x.png");
  // display: block;
  // width: 13px;
  // height: 13px;
  // background-size: contain;
// }

// form左侧宽度
::v-deep .van-field__label {
  width: 10em;
}
.button-div {
  text-align: center;
  background-color: #f2f2f2;
  padding-top: 40px;
  padding-bottom: 34px;
}
.van-button--normal {
  height: 48px;
  width: 343px;
  border-radius: 4px;
}
.col-inp .van-cell{
  padding: 12px 14px;
}
.col-inp .van-button--small{
  margin: 7px 0;
}
</style>
