var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bg-[#f2f2f2]" },
    [
      _c(
        "van-list",
        {
          staticClass: "list-div",
          attrs: { finished: _vm.finish, "finished-text": "没有更多了" },
          on: { load: _vm.onLoad },
        },
        _vm._l(_vm.data, function (item, index) {
          return _c("div", { key: index, staticClass: "bg-[#ffffff]" }, [
            _c(
              "div",
              { staticClass: "px-16px py-14px" },
              [
                _c(
                  "van-row",
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "6" } },
                      [_vm._v("任务编号:")]
                    ),
                    _c("van-col", { attrs: { span: "18" } }, [
                      _vm._v(_vm._s(item.orderNo)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "6" } },
                      [_vm._v("处理人:")]
                    ),
                    _c("van-col", { attrs: { span: "18" } }, [
                      _vm._v(_vm._s(item.handlerName)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "6" } },
                      [_vm._v("作业方式:")]
                    ),
                    _c("van-col", { attrs: { span: "18" } }, [
                      _vm._v(_vm._s(item.taskTypeName)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "6" } },
                      [_vm._v("任务描述:")]
                    ),
                    _c("van-col", { attrs: { span: "18" } }, [
                      _vm._v(_vm._s(item.remark)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "8" } },
                      [_vm._v("计划开始时间:")]
                    ),
                    _c("van-col", { attrs: { span: "16" } }, [
                      _vm._v(_vm._s(item.planStart)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "8" } },
                      [_vm._v("计划结束时间:")]
                    ),
                    _c("van-col", { attrs: { span: "16" } }, [
                      _vm._v(_vm._s(item.planEnd)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "8" } },
                      [_vm._v("实际开始时间:")]
                    ),
                    _c("van-col", { attrs: { span: "16" } }, [
                      _vm._v(_vm._s(item.actualStart)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "8" } },
                      [_vm._v("实际结束时间:")]
                    ),
                    _c("van-col", { attrs: { span: "16" } }, [
                      _vm._v(_vm._s(item.actualEnd)),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("p", { staticClass: "bg-line" }),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }