var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Cascader", {
    ref: "cascaderRef",
    attrs: {
      title: "所在项目列表",
      initList: _vm.projectList,
      "cb-select": _vm.projectItem,
      "select-type": "radio",
      cbGoBack: _vm.projectBack,
      required: "",
    },
    on: { search: _vm.handleSearch, confirm: _vm.confirm },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }