<template v-cloak>
    <div class="offline-list-wrapper">
      <my-nav title="待上传离线设施设备数据">
          <p slot="right" class="submit-btn" :class="{'disabled':submitLoading}" @click="handleSubmit(list)">全部提交</p>
      </my-nav>
      <div class="list-wrapper" v-if="list.length > 0">
        <div v-for="(item, index) in list" :key="index">
          <div class="order-cell" >
            <div class="order-cell-title">
              <div class="flex-box">
                <div class="order-number">{{ item.qrCode }}</div>
              </div>
              <div class="btn-wrapper">
                <p class="btn del" :class="{'disabled':submitLoading}" @click="handleDelete(item)">删除</p>
                <p class="btn" :class="{'disabled':submitLoading}" @click="handleSubmit([item])" v-if="!item.repeatSubmit">提交</p>
              </div>
            </div>
            <div class="order-body">
              <van-row class="order-body-cell">
                <van-col :span="6" class="sub-key">所在项目:</van-col>
                <van-col :span="18" class="sub-value">
                  <span>{{ item.projectName }}</span>
                </van-col>
              </van-row>
              <van-row class="order-body-cell">
                <van-col :span="6" class="sub-key">所在组团:</van-col>
                <van-col :span="16" class="sub-value">
                  <span>{{ item.projectGroupName }}</span>
                </van-col>
              </van-row>
              <van-row class="order-body-cell">
                <van-col :span="6" class="sub-key">{{item.createType==='equip'?'设备':'设施'}}分类:</van-col>
                <van-col :span="16" class="sub-value">
                  <span>{{ item.categoryFullName }}</span>
                </van-col>
              </van-row>
              <van-row class="order-body-cell">
                <van-col :span="6" class="sub-key">{{item.createType==='equip'?'设备':'设施'}}名称:</van-col>
                <van-col :span="18" class="sub-value">
                  <span>{{ item.name }}</span>
                </van-col>
              </van-row>
              <van-row class="order-body-cell">
                <van-col :span="6" class="sub-key">{{item.createType==='equip'?'设备':'设施'}}位置:</van-col>
                <van-col :span="18" class="sub-value">
                  <span>{{ item.location }}</span>
                </van-col>
              </van-row>
              <div v-if="item.repeatSubmit" class="repeatSubmit">重复登记</div>
            </div>
          </div>
        </div>
      </div>
      <no-data v-else tex="暂无待上传离线设备数据"></no-data>
    </div>
</template>

<script type='text/ecmascript-6'>
import { apiOffline } from '@/plugins/offlineMixin/apiOfflineGetway'
import api from '@/api'
import {
  getTobeUploadEquipment,
  delTobeUploadEquipmentByQrcodes,
  updateTobeUploadEquipmentByQrcodes
} from '@/plugins/offlineMixin/offlineApiHandlers/tobeUploadEquipmentOfflineApiHandler.js'
import {
  uploadPhotoByImgKeys
} from '@/plugins/offlineMixin/utils/offlineImgTool'

export default {
  name: 'tobeUpload',
  components: {},
  data () {
    return {
      list: [],
      ossParams: null,
      submitLoading: false
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    }
  },
  async created () {
    this.list = await getTobeUploadEquipment() || []
    console.log('created---this.list: ', this.list)
  },
  methods: {
    // 删除
    async handleDelete (item) {
      console.log('数据item: ', item)
      if (this.submitLoading) return
      this.$dialog
        .confirm({
          message: '确定删除该离线数据嘛？',
          confirmButtonColor: '#3366FE',
          cancelButtonColor: '#3366FE'
        })
        .then(async () => {
          this.list = await apiOffline({
            offlineApiHandlers: delTobeUploadEquipmentByQrcodes,
            params: { qrcodes: [item.qrCode] },
            forceOffline: true
          })
          console.log('this.list: ', this.list)
        })
        .catch((err) => {
          console.log('err: ', err)
        })
    },
    // 提交
    async handleSubmit (array) {
      if (this.submitLoading) return
      this.submitLoading = true
      // 离线不能提交
      if (!this.isOffline) {
        this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
        this.submitLoading = false
        return
      }
      try {
        const formArr = this.lodash.cloneDeep(array.filter(item => !item.repeatSubmit)) // 筛选出不是重复提交的
        if (formArr.length === 0) return this.$toast('无可提交的数据')
        for (let index = 0; index < formArr.length; index++) {
          const form = formArr[index]
          form.attachementModelList = await uploadPhotoByImgKeys(form.attachementModelList)
          // 先组装图片
          form.type = form.createType === 'equip' ? 3 : 2
          form.projectGroupCode = form.groupCode
          form.source = 'APP'
        }
        console.log('formArr2: ', this.lodash.cloneDeep(formArr))
        const data = await this.$http.post(api.commitFacilityAndEquipment, formArr)
        console.log('data: ', data)

        // successList 提交成功二维码集合
        // failList 提交失败二维码集合 key为二维码，value为错误信息
        const { successList = [], failList = [] } = data
        if (successList.length > 0) {
          // 离线包删除提交成功的
          this.list = await apiOffline({
            offlineApiHandlers: delTobeUploadEquipmentByQrcodes,
            params: { qrcodes: successList },
            forceOffline: true
          })
          this.$dialog.alert({
            title: '提示',
            message: `${successList.join('、')}提交成功`,
            confirmButtonColor: '#3366FE',
            confirmButtonText: '知道了'
          })
        }
        if (failList.length > 0) {
          // failList
          const fialQrcodes = failList.reduce((arr, item) => {
            arr.push(Object.keys(item)[0])
            return arr
          }, [])
          this.list = await apiOffline({
            offlineApiHandlers: updateTobeUploadEquipmentByQrcodes,
            params: { qrcodes: fialQrcodes },
            forceOffline: true
          })
          for (let index = 0; index < fialQrcodes.length; index++) {
            const code = fialQrcodes[index]
            await this.failAlert(failList.find(i => Object.keys(i).includes(code))[code])
          }
        }
      } catch (error) {
        console.error('提交设施设备 error: ', error)
        this.submitLoading = false
      } finally {
        this.submitLoading = false
      }
    },
    failAlert (message) {
      return new Promise((resolve) => {
        this.$dialog.alert({
          title: '提示',
          message,
          confirmButtonColor: '#3366FE',
          confirmButtonText: '知道了'
        }).then(() => {
          resolve()
        })
      })
    }
  }
}
</script>
<style  lang='less' scoped>
@import url("./index.less");
</style>
