<template>
  <!-- 二维码已登记 -->
  <div>
    <van-nav-bar class="nav nav-header-home" fixed z-index="999" :placeholder="true">
      <template v-slot:title>
        <span class="nav-title-text">设施/设备台账</span>
      </template>
      <template v-slot:left>
        <div class="icon-back" @click="toBack"></div>
      </template>
    </van-nav-bar>
    <p class="bg-line"></p>
    <van-tabs v-model="active" color="#3366FE" title-inactive-color="#7C7F8A"
      title-active-color="#3366FE" line-height="2px">
      <van-tab class="base" title-class="tabs-title" title="基本信息" name="base">
        <van-cell-group>
          <van-field readonly required label="所在项目" v-model="formData.projectName" />
          <van-field readonly required label="所在组团"
            :value="formData.groupName|| formData.projectGroupName" />
          <van-field readonly required :label="typeName+'分类'" v-model="formData.categoryName" />
          <van-field readonly required :label="typeName+'名称'" v-model="formData.name" />
          <van-field readonly required :label="typeName+'编码'" v-model="formData.code" />
          <van-field readonly required label="接管日期" v-model="formData.takeoverTime" />

          <van-field v-if="sizeCode==2 || sizeCode==3" readonly required label="设备品牌"
            v-model="formData.brandName" />

          <van-field v-if="sizeCode==4 || sizeCode==2" readonly required :label="typeName+'数量'"
            v-model="formData.deviceNum" />
          <van-field v-if="sizeCode==3" readonly required label="型号或规格"
            v-model="formData.modelSpec" />
          <van-field v-if="sizeCode==2 || sizeCode==3" readonly required label="维保频次"
            v-model="formData.maintenanceTimeName" />
          <van-field v-if="sizeCode==3" readonly required label="出厂日期" v-model="formData.pdDate" />
          <van-field v-if="sizeCode==3" readonly required label="合同质保到期日"
            v-model="formData.expiryDate" />
          <van-field v-if="sizeCode==1 || sizeCode==3" readonly required label="是否监控"
            :value="formData.isMonitor==1?'是':'否'" />
          <template v-if="sizeCode==3">
            <van-field readonly required label="设备价值(元)" v-model="formData.deviceAmount" />
            <van-field readonly required label="检验周期(年)" v-model="formData.checkoutTime" />
            <van-field readonly required label="上次检验日期" v-model="formData.lastCheckoutTime" />
            <van-field readonly v-for="(item,index) in formData.extras" :key='index'
              v-model='item.model' :required='index===0' :label="item.name" />
          </template>
          <p class="bg-line"></p>
          <van-field readonly :label="typeName+'位置'" rows="2" class="form-textarea" type="textarea" required
            v-model="formData.location" />
          <p class="bg-line"></p>
          <van-field readonly :label="typeName+'说明'" rows="2" class="form-textarea" type="textarea"
            v-model="formData.remark" />
          <div v-if="formData.attachementModelList && formData.attachementModelList.length">
            <p class="bg-line"></p>
            <div class="img-div">
              <van-image readonly radius="2vw" v-for="(item, index) in imageArr" :key="index"
                width="12vw" height="12vw" @click="imgPreview(index)" :src="item.url" />
              <div v-for="(docItem,docIndex) in docxArr" :key="'doc'+docIndex">
                <img class="w-12px h-13px align-middle mr-6px" src="~@/asset/QRCode/paperClip.svg">
                <span class="text-14px align-middle">{{docItem.name}}</span>
              </div>
            </div>
          </div>
        </van-cell-group>
      </van-tab>
      <van-tab :title="'巡检记录('+ checkDataTotal +')'" name="check" title-class="tabs-title-change">
        <check-list :data='checkData' @page='checkPage' :finish='checkFinish'/>
      </van-tab>
      <van-tab :title="'保养记录('+ holdDataTotal +')'" name="hold" title-class="tabs-title-change">
        <hold-list :data='holdData' @page='holdPage' :finish='checkFinish'/>
      </van-tab>
      <van-tab :title="'维修记录('+ repairDataTotal +')'" name="repair" title-class="tabs-title-change">
        <repair-list :data='repairData' @page='repairPage' :finish='repairFinish' />
      </van-tab>
      <van-tab :title="'变更记录('+ changeList.length +')'" name="change"
        title-class="tabs-title-change">
        <van-steps direction="vertical" active-color="#969799" class="stpe-component">
          <van-step v-for="(item, index) in changeList" :key="index">
            <!--时间 -->
            <p class="text-14px text-[#8B8F9A] pt-4px">{{ item.lastUpdateDate }}</p>
            <!-- 状态前缀 -->
            <span class="text-16px text-[#222222]">
              {{item.operType|calcTypeText}}
            </span>
            <!-- 操作人 -->
            <span v-if="item.operType === '1' || item.operType === '2'"
              class="text-16px text-[#222222]">
              {{
                `【${item.createName}】`
              }}</span>
            <span v-if="
                item.operType === 'D1' ||
                item.operType === 'D2' ||
                item.operType === 'D3' ||
                item.operType === 'D4' ||
                item.operType === 'D5'
              " class="text-16px text-[#222222]">
              {{
                `${item.operTypeAction}状态【${item.createName}】`
              }}
            </span>
            <!-- 查看历史按钮 + 操作人 -->
            <span v-if="item.operType === '3'">
              <span class="text-16px text-[#222222]">{{item.content}}</span>
              <!-- 暂时不做点击 如果要使用 解除下面注释内容 -->
              <!-- <a class="text-16px text-[#3366fe] underline" type="link" @click="queryDetails(item.hisDetail)">
                {{item.content}}
              </a> -->
              <span class="text-16px text-[#222222]" style="margin-left: -5px">{{
                `【${item.createName}】`
              }}</span></span>
          </van-step>
        </van-steps>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
// import selectEquipM from './component/selectEquip'
import { ImagePreview } from 'vant'
import checkList from './component/checkList.vue' // 巡检记录组件
import holdList from './component/holdList.vue' // 保养记录组件
import repairList from './component/repairList.vue'// 维修记录组件
import api from '@/api/index'

export default {
  components: { checkList, repairList, holdList },
  created () {
    // 基本信息 2设施 3设备
    this.type = this.$route.query.type
    this.typeName = this.$route.query.type === '2' ? '设施' : '设备'
    this.qrCode = this.$route.query.qrCode
    this.renderForm(this.qrCode)
    this.renderCount()
    this.renderChange({ type: this.type, code: this.qrCode })
    this.renderCheck()
    this.renderHold()
    this.renderRepair()
  },
  filters: {
    calcTypeText (val) {
      const m = new Map([
        ['1', '创建设施'],
        ['2', '导入设施'],
        ['3', '变更信息,点击查看变更前信息']
      ])
      return m.get(val) || ''
    }
  },
  data () {
    return {
      type: 2,
      typeName: undefined, // 二维码的标识 判断label
      sizeCode: undefined, // 管理粒度的标识
      qrCode: undefined, // 二维码code 传给接口回填的
      formData: {
        attachementModelList: [],
        brandName: undefined
      },
      countOptions: [], // 频次
      changeList: [], // 变更记录
      active: 'base',
      selectEquipShow: false,
      imageArr: [],
      docxArr: [],
      imageRules: [
        'BMP',
        'DIB',
        'PCP',
        'DIF',
        'WMF',
        'GIF',
        'JPG',
        'TIF',
        'EPS',
        'PSD',
        'CDR',
        'IFF',
        'TGA',
        'PCD',
        'MPT',
        'PNG'
      ],
      checkObj: {
        pointCode: undefined,
        pointType: undefined, // 设备3 设施2
        page: 1,
        limit: 10,
        standardPropId: 108
      },
      checkDataTotal: 0,
      checkData: [],
      checkFinish: false,
      holdObj: {
        pointCode: undefined,
        pointType: undefined, // 设备3 设施2
        page: 1,
        limit: 10,
        standardPropId: 107
      },
      holdDataTotal: 0,
      holdData: [],
      holdFinish: false,
      repairObj: {
        pointCode: undefined,
        pointType: undefined, // 设备3 设施2
        page: 1,
        limit: 10
      },
      repairDataTotal: 0,
      repairData: [],
      repairFinish: false

    }
  },
  methods: {
    async renderForm (code) {
      if (this.type === '3') {
        const equipData = await this.$http.get(api.equipDetail + '/' + code)
        this.formData = equipData
        this.formData.attachementModelList =
          equipData.attachementModelList || []
      }
      if (this.type === '2') {
        const facilityData = await this.$http.get(
          api.facilityDetail + '/2/' + code
        )
        this.formData = facilityData
        this.formData.attachementModelList =
          facilityData.attachementModelList || []
      }
      // 图片显示
      this.imageArr = [];
      // 判断图片后缀是否有要显示的 如果是图片 存image 否则存docx
      (this.formData.attachementModelList || []).forEach((i) => {
        if (
          this.imageRules.includes(
            i.url.substr(i.url.lastIndexOf('.') + 1).toUpperCase()
          )
        ) {
          this.imageArr.push({ url: i.url })
        } else {
          this.docxArr.push({ url: i.url, name: i.name })
        }
      })
      // 粒度 14设施 23设备
      this.sizeCode =
        this.formData.manageGrainSizeCode === 'manageGrainSize1'
          ? 1
          : this.formData.manageGrainSizeCode === 'manageGrainSize2'
            ? 2
            : this.formData.manageGrainSizeCode === 'manageGrainSize3'
              ? 3
              : this.formData.manageGrainSizeCode === 'manageGrainSize4'
                ? 4
                : ''
      // 匹配频次
      this.findCount()
      this.renderBrand()
    },
    // 频次
    async renderCount () {
      // 频次字典
      const data = await this.$http.get(api.dictionaryitem, {
        dic_code: 'maintenanceTime'
      })
      this.countOptions = data || []
    },
    // 匹配频次
    findCount () {
      if (this.formData.maintenanceTimeCode) {
        this.formData.maintenanceTimeName = this.countOptions.find(
          (i) => i.code === this.formData.maintenanceTimeCode
        ).name
      }
    },
    // 变更历史
    async renderChange (obj) {
      const { type, code } = obj
      const data = await this.$http.get(
        api.changeList + '/' + type + '/' + code
      )
      this.changeList = data
    },
    // 变更点击
    queryDetails (value) {
      this.$toast('已显示为本次变更记录')
      this.active = 'base'
      this.formData = value
      // 匹配频次
      this.findCount()
    },
    imgPreview (index) {
      this.imagesGroup = []
      this.imageArr.forEach((item) => {
        this.imagesGroup.push(item.url)
      })
      ImagePreview({
        images: this.imagesGroup,
        startPosition: index
      })
    },
    async renderBrand () {
      const data = await this.$http.get(
        api.equipBrand + '/' + this.formData.categoryCode
      )
      let classMake = data?.classMake || []
      if (!classMake.find((i) => i.makeCode === this.formData.brandCode)) {
        this.formData.brandName = this.formData.brandCode
      }
    },
    // 巡检记录
    async renderCheck () {
      this.checkObj.pointType = parseInt(this.$route.query.type)
      this.checkObj.pointCode = this.qrCode
      const data = await this.$http.get(api.checkList, this.checkObj)
      if (this.checkData.length === data.total) {
        this.checkFinish = true
        return
      }
      data.records.forEach(i => {
        this.checkData.push(i)
      })
      this.checkDataTotal = data.total
    },
    checkPage (page) {
      this.checkObj.page = page
      this.renderCheck()
    },
    // 保养记录
    async renderHold () {
      this.holdObj.pointType = parseInt(this.$route.query.type)
      this.holdObj.pointCode = this.qrCode
      const data = await this.$http.get(api.checkList, this.holdObj)
      if (this.holdData.length === data.total) {
        this.holdFinish = true
        return
      }
      data.records.forEach(i => {
        this.holdData.push(i)
      })
      this.holdDataTotal = data.total
    },
    holdPage (page) {
      this.holdObj.page = page
      this.renderHold()
    },
    // 维修记录
    async renderRepair () {
      this.repairObj.pointType = parseInt(this.$route.query.type)
      this.repairObj.pointCode = this.qrCode
      const data = await this.$http.get(api.repairList, this.repairObj)
      if (this.repairData.length === data.total) {
        this.repairFinish = true
        return
      }
      data.records.forEach(i => {
        this.repairData.push(i)
      })
      this.repairDataTotal = data.total
    },
    repairPage (page) {
      this.repairObj.page = page
      this.renderRepair()
    },
    toBack () {
      if (this.$route.query.isOrder) { this.$router.back() } else { this.$router.replace({ path: '/QRCodeScan' }) }
    }
  }
}
</script>

<style lang="less" scoped>
.nav {
  .nav-title-text {
    font-size: 18px;
    font-weight: 601;
    color: #222222;
  }
  .icon-back {
    height: 24px;
    width: 24px;
    background: url("../../asset/icon_back_black@2x.png") no-repeat;
    background-size: cover;
  }
}
/* tab底部颜色的长度 */
::v-deep .van-tabs__line {
  width: 20%;
}
/* tab文字换行 */
::v-deep .tabs-title .van-tab__text--ellipsis {
  display: block;
  padding: 5px 16px;
}
::v-deep .tabs-title-change .van-tab__text--ellipsis {
  display: block;
  padding: 5px 4px;
  text-align: center;
}
/* tabs高度 */
::v-deep .van-tabs__wrap {
  height: 60px;
}
// 灰色分割
.bg-line {
  width: 100%;
  height: 8px;
  background-color: #f2f2f2;
  margin-bottom: 0;
}
.img-div {
  padding: 16px;
}
.van-image {
  margin-right: 12px;
}
// form左侧宽度
::v-deep .van-field__label {
  width: 10em;
}
// steps
::v-deep .van-icon-checked:before {
  border: 0.53vw solid #3366fe;
  background: #fff;
}
</style>
