var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bg-[#f2f2f2]" },
    [
      _c(
        "van-list",
        {
          staticClass: "list-div",
          attrs: { finished: _vm.finish, "finished-text": "没有更多了" },
          on: { load: _vm.onLoad },
        },
        _vm._l(_vm.data, function (item, index) {
          return _c("div", { key: index, staticClass: "bg-[#ffffff]" }, [
            _c(
              "div",
              { staticClass: "px-16px py-14px" },
              [
                _c(
                  "van-row",
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "6" } },
                      [_vm._v("工单编号:")]
                    ),
                    _c("van-col", { attrs: { span: "18" } }, [
                      _vm._v(_vm._s(item.orderNo)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "6" } },
                      [_vm._v("工单状态:")]
                    ),
                    _c("van-col", { attrs: { span: "18" } }, [
                      _vm._v(_vm._s(item.orderStatusName)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "8" } },
                      [_vm._v("标准作业程序:")]
                    ),
                    _c("van-col", { attrs: { span: "16" } }, [
                      _vm._v(_vm._s(item.taskWork)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "6" } },
                      [_vm._v("所在项目:")]
                    ),
                    _c("van-col", { attrs: { span: "18" } }, [
                      _vm._v(_vm._s(item.projectName)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "6" } },
                      [_vm._v("问题描述:")]
                    ),
                    _c("van-col", { attrs: { span: "18" } }, [
                      _vm._v(_vm._s(item.problemName)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "6" } },
                      [_vm._v("问题分级:")]
                    ),
                    _c("van-col", { attrs: { span: "18" } }, [
                      _vm._v(_vm._s(item.problemClassify)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "6" } },
                      [_vm._v("记录人:")]
                    ),
                    _c("van-col", { attrs: { span: "18" } }, [
                      _vm._v(_vm._s(item.recordName)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "6" } },
                      [_vm._v("记录时间:")]
                    ),
                    _c("van-col", { attrs: { span: "18" } }, [
                      _vm._v(_vm._s(item.recordTime)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "6" } },
                      [_vm._v("整改时限:")]
                    ),
                    _c("van-col", { attrs: { span: "18" } }, [
                      _vm._v(_vm._s(item.rectifyLimit)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "van-row",
                  [
                    _c(
                      "van-col",
                      { staticClass: "text-[#8B8F9A]", attrs: { span: "6" } },
                      [_vm._v("整改人:")]
                    ),
                    _c("van-col", { attrs: { span: "18" } }, [
                      _vm._v(_vm._s(item.handlerName)),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("p", { staticClass: "bg-line" }),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }