<template>
  <div>
    <van-nav-bar
      class="nav nav-header-home"
      fixed
      z-index="999"
      :placeholder="true"
    >
      <template v-slot:title>
        <span class="nav-title-text">设备数据</span>
      </template>
      <template v-slot:left>
        <div class="icon-back" @click="toBack"></div>
      </template>
    </van-nav-bar>
    <div class="div-center">
      <!-- <van-button class="console_btn" type="primary" @click="getPreMadeQRCodeHandler">打印离线form包</van-button>
      <van-button class="console_btn" type="primary" @click="delPreMadeQRCodeHandler">删除离线form包</van-button>
      <van-button class="console_btn" type="primary" @click="getTobeUploadEquipmentHandler">打印离线上传包</van-button>
      <van-button class="console_btn" type="primary" @click="delTobeUploadEquipmentHandler">删除离线上传包</van-button> -->
      <lottie
        class="scan-img"
        :height="300"
        :options="logoDefaultOptions"
        ref="lottie"
      />
      <p class="hint">请扫码登记或查看设施/设备数据</p>
      <van-button color="#3366FE" @click="jumpScan"
        ><img class="QRCode-icon" src="~@/asset/QRCode/scan-icon.svg" />
        <span class="button-text">扫一扫</span>
      </van-button>
      <van-button color="#3366FE" @click="download">
        <span class="button-text">下载</span>
      </van-button>
      <van-button color="#3366FE" @click="upload">
        <span class="button-text">上传</span>
      </van-button>
    </div>
    <van-popup v-model="downloadShow" class="circle-wrapper">
      <p class="title">正在同步数据，请不要离开!</p>
      <van-circle
        v-model="donloadCircle"
        :rate="30"
        :speed="100"
        color="#3366FE"
        layer-color="#EBECF0"
        stroke-width="70"
        text="下载数据中"
      />
      <p class="desc">正在下载数据…</p>
    </van-popup>
    <van-popup
      v-model="downloadSuccessShow"
      class="circle-wrapper success-wrapper"
    >
      <p class="title">恭喜您，下载完成!!</p>
      <div class="success-icon"></div>
      <p class="desc">下载完成</p>
    </van-popup>
  </div>
</template>

<script>
import mobile from '@/utils/mobile'
import lottie from '@/components/lottie'
import scan from '@/asset/QRCode/scan_qr_code.json'
import api from '@/api/index'
import {
  setPreMadeQRCodeHandler,
  delPreMadeQRCodeHandler,
  getPreMadeQRCodeHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/preMadeQRCodeOfflineApiHandler.js'
import {
  getTobeUploadEquipment,
  delTobeUploadEquipment
} from '@/plugins/offlineMixin/offlineApiHandlers/tobeUploadEquipmentOfflineApiHandler.js'
export default {
  components: { lottie },
  data () {
    return {
      logoDefaultOptions: { animationData: scan },
      downloadSuccessShow: false,
      downloadShow: false,
      donloadCircle: 0
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    }
  },
  methods: {
    toBack () {
      mobile.backWeb()
    },
    async jumpScan () {
      const res = await mobile.getQRCodeInfo()
      console.log('res: ', res)
      if (this.isOffline) {
        this.jump(res)
      } else {
        const data = await getTobeUploadEquipment() || []
        const targetQRcode = data.findIndex(item => item.qrCode === res)
        if (targetQRcode === -1) {
          this.$storage.set('qrCode', res)
          this.$router.push({
            path: '/QRCodeScan/QRCodeNoRegister'
          })
        } else {
          this.$dialog.alert({
            title: '提示',
            message: `${res}已离线登记至待上传列表，请在网络情况好的状态下进行上传数据。`,
            confirmButtonColor: '#3366FE',
            confirmButtonText: '知道了'
          })
        }
      }
    },
    async jump (code) {
      const data = await this.$http.get(api.QRCodeType + '/' + code)
      if (data.type === '0' || data.type === '-1') {
        this.$storage.set('qrCode', code)
        this.$router.push({
          path: '/QRCodeScan/QRCodeNoRegister'
        })
      }
      if (data.type === '2' || data.type === '3') {
        this.$router.push({
          path: '/QRCodeScan/QRCodeRegister',
          query: { type: data.type, qrCode: data.code }
        })
      }
      if (data.type === '1') {
        this.$toast.fail('二维码无效')
      }
    },
    async getPreMadeQRCodeHandler () {
      const data = await getPreMadeQRCodeHandler()
      console.log('getPreMadeQRCodeHandler: ', data)
    },
    async delPreMadeQRCodeHandler () {
      await delPreMadeQRCodeHandler()
      this.$notify({
        type: 'success',
        message: '成功'
      })
    },
    async getTobeUploadEquipmentHandler () {
      const data = await getTobeUploadEquipment()
      console.log('data: ', data)
    },
    async delTobeUploadEquipmentHandler () {
      await delTobeUploadEquipment()
      this.$notify({
        type: 'success',
        message: '成功'
      })
    },
    async download () {
      if (!this.isOffline) {
        this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
        return
      }
      this.donloadCircle = 0
      this.downloadShow = true
      // 模拟假的数据条
      let time = setInterval(() => {
        if (this.donloadCircle < 90) {
          this.donloadCircle += 5
        } else if (this.donloadCircle > 90 <= 95) {
          this.donloadCircle += 2
          clearInterval(time)
        }
      }, 200)
      try {
        const data = await this.$http.get(api.facilityAndEquipmentPackage)
        console.log('data: ', data)
        await setPreMadeQRCodeHandler(data)
        this.donloadCircle = 100
        let closeDownloadShowTimer = setTimeout(() => {
          this.downloadShow = false
          // 显示下载成功
          this.downloadSuccessShow = true
          let downloadSuccessShowTimer = setTimeout(() => {
            this.downloadSuccessShow = false
            clearTimeout(downloadSuccessShowTimer)
          }, 1500)
          clearTimeout(closeDownloadShowTimer)
        }, 400)
      } catch (error) {
        console.log('error: ', error)
        this.$notify({
          type: 'warning',
          message: '下载失败'
        })
      } finally {
        this.downloadShow = false
        clearInterval(time)
      }
    },
    upload () {
      this.$router.push('/QRCodeScan/tobeUpload')
    }
  }
}
</script>

<style lang="less" scoped>
.nav {
  .nav-title-text {
    font-size: 18px;
    font-weight: 601;
    color: #222222;
  }
  .icon-back {
    height: 24px;
    width: 24px;
    background: url("../../asset/icon_back_black@2x.png") no-repeat;
    background-size: cover;
  }
}
.div-center {
  text-align: center;
}
.QRCode-icon {
  align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 8px;
}
.button-text {
  vertical-align: text-bottom;
}
.hint {
  color: #8b8f9a;
  font-size: 14px;
}
.van-button--normal {
  margin-top: 10px;
  height: 48px;
  width: 295px;
  box-shadow: 0px 3px 12px rgba(51, 102, 254, 0.4);
  border-radius: 4px;
}
.circle-wrapper {
  width: 280px;
  // height: 216px;
  border-radius: 8px;
  &.success-wrapper {
    .success-icon {
      width: 100px;
      height: 100px;
      background: url("../../asset/success_icon.png") no-repeat;
      background-size: 100% 100%;
      margin: 16px auto;
    }
  }
  p {
    margin: 0;
    text-align: center;
  }
  .title {
    font-size: 18px;
    font-weight: 400;
    color: #222222;
    line-height: 28px;
    margin-top: 16px;
  }
  .van-circle {
    margin: 16px 90px;
  }
  .desc {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    margin-bottom: 16px;
  }
}
.console_btn{
  display: inline-block;
  width: 200px;
}
</style>
