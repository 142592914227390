<template>
  <Cascader
    ref="cascaderRef"
    title="所在项目列表"
    :initList="projectList"
    :cb-select="projectItem"
    select-type="radio"
    :cbGoBack="projectBack"
    @search="handleSearch"
    @confirm="confirm"
    required
  />
</template>

<script>
import Cascader from '@/components/Cascader'
import api from '@/api/index'
import {
  getPreMadeQRCodeHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/preMadeQRCodeOfflineApiHandler.js'

export default {
  components: { Cascader },
  created () {
    this.renderProject()
  },
  data () {
    return {
      projectList: [],
      treeChild: [],
      searchVal: [],
      preMadeQRCodeOfflineData: null
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    }
  },
  watch: {
    isOffline () {
      this.$refs.cascaderRef.clearSelectData()
      this.projectList = []
      this.renderProject()
    }
  },
  methods: {
    confirm (selectCodes, selectDetails) {
      console.log('selectCodes, selectDetails: ', selectCodes, selectDetails)
      const obj = selectDetails[0]
      // 判断是从设备还是设施点进来的
      obj.clickType = this.$route.query.type
      this.$EB.$emit(this.$EBT.ProjectList, obj)
    },
    // 渲染项目
    async renderProject () {
      // 在线
      if (this.isOffline) {
        const data = await this.$http.get(api.getOrg)
        this.projectList = data.reduce((arr, item) => {
          arr.push({
            code: item.deptCode,
            codeName: item.deptName,
            children: []
          })
          return arr
        }, [])
      } else {
        // 离线
        const preMadeQRCodeOfflineData = await getPreMadeQRCodeHandler()
        if (preMadeQRCodeOfflineData) {
          // 保存一份
          this.preMadeQRCodeOfflineData = preMadeQRCodeOfflineData
          const { projectTrees = [] } = this.lodash.cloneDeep(this.preMadeQRCodeOfflineData)
          projectTrees.forEach((item) => {
            item.codeName = item.full_name
            item.children = this.PJtransformCausesList(item.children || [])
          })
          this.projectList = projectTrees
        } else {
          this.$notify({
            type: 'warning',
            message: '您的网络状态不好，请提前下载离线数据'
          })
        }
      }
    },
    projectItem (item, breadCrumbsList) {
      console.log('item, breadCrumbsList: ', this.lodash.cloneDeep(item), this.lodash.cloneDeep(breadCrumbsList))
      if (breadCrumbsList.length === 1 && item.children) {
        if (this.isOffline) {
          this.$http.get(api.projectTree, { name: item.name }).then(res => {
            const newList = this.PJtransformCausesList(res[0].children || [])
            console.log('newList: ', newList)
            this.$set(this.projectList[item.index], 'children', newList)
          })
        }
        return true
      } else if (!item.children) {
        // const obj = item
        // // 判断是从设备还是设施点进来的
        // obj.clickType = this.$route.query.type
        // this.$EB.$emit(this.$EBT.ProjectList, obj)
        // this.equipForm.projectName = item.codeName
        // this.equipForm.projectCode = item.code
        // this.renderGroup(this.equipForm.projectCode) // 选择项目后
      }
    },
    PJtransformCausesList (source) {
      let arr = []
      source.forEach(item => {
        const { code, title, children, ...moreVal } = item
        let child
        if (children && children.length > 0) {
          child = this.PJtransformCausesList(children)
        }
        arr.push({ code: code, codeName: title, children: child, ...moreVal })
      })
      return arr
    },
    async handleSearch ({ searchValue }) {
      console.log('searchValue: ', searchValue)
      if (!searchValue) return
      let res
      if (this.isOffline) {
        res = await this.$http.get(api.projectTree)
      } else {
        const { projectTrees = [] } = this.lodash.cloneDeep(this.preMadeQRCodeOfflineData)
        res = projectTrees
      }
      const newList = this.PJtransformCausesList(res[0].children || [])
      // 先递归获取所有子节点 然后匹配搜索的值 渲染
      this.treeChild = []
      this.searchVal = []
      this.tree(newList)
      this.treeChild.forEach(i => {
        if (i.codeName.indexOf(searchValue) !== -1) {
          this.searchVal.push(i)
        }
      })
      this.$refs.cascaderRef.setSearchList(
        this.searchVal
        // newList.reduce((arr, item) => {
        // if (item.codeName.indexOf(searchValue) !== -1) {
        //   return arr.concat(item.children)
        // }
        // return arr
        // }, [])
      )
    },
    // 所有的子节点
    tree (tree) {
      tree.forEach(item => {
        if (!item.children) {
          this.treeChild.push(item)
        } else {
          this.tree(item.children)
        }
      })
    },
    projectBack () {
      this.$router.back()
    }
  }
}
</script>

<style></style>
