var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Cascader", {
    ref: "cascaderRef",
    attrs: {
      title: "设备分类列表",
      initList: _vm.equipList,
      "cb-select": _vm.equipItem,
      "select-type": "radio",
      cbGoBack: _vm.equipBack,
    },
    on: { search: _vm.handleSearch, confirm: _vm.confirm },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }