<template>
  <div class="bg-[#f2f2f2]">
    <van-list class="list-div" @load="onLoad" :finished='finish' finished-text="没有更多了">
      <div v-for="(item,index) in data" :key="index" class="bg-[#ffffff]">
        <div class="px-16px py-14px">
          <van-row>
            <van-col class="text-[#8B8F9A]" span="6">工单编号:</van-col>
            <van-col span="18">{{item.orderNo}}</van-col>
          </van-row>
          <van-row>
            <van-col class="text-[#8B8F9A]" span="6">工单状态:</van-col>
            <van-col span="18">{{item.orderStatusName}}</van-col>
          </van-row>
          <van-row>
            <van-col class="text-[#8B8F9A]" span="8">标准作业程序:</van-col>
            <van-col span="16">{{item.taskWork}}</van-col>
          </van-row>
          <van-row>
            <van-col class="text-[#8B8F9A]" span="6">所在项目:</van-col>
            <van-col span="18">{{item.projectName}}</van-col>
          </van-row>
          <van-row>
            <van-col class="text-[#8B8F9A]" span="6">问题描述:</van-col>
            <van-col span="18">{{item.problemName}}</van-col>
          </van-row>
          <van-row>
            <van-col class="text-[#8B8F9A]" span="6">问题分级:</van-col>
            <van-col span="18">{{item.problemClassify}}</van-col>
          </van-row>
          <van-row>
            <van-col class="text-[#8B8F9A]" span="6">记录人:</van-col>
            <van-col span="18">{{item.recordName}}</van-col>
          </van-row>
          <van-row>
            <van-col class="text-[#8B8F9A]" span="6">记录时间:</van-col>
            <van-col span="18">{{item.recordTime}}</van-col>
          </van-row>
          <van-row>
            <van-col class="text-[#8B8F9A]" span="6">整改时限:</van-col>
            <van-col span="18">{{item.rectifyLimit}}</van-col>
          </van-row>
          <van-row>
            <van-col class="text-[#8B8F9A]" span="6">整改人:</van-col>
            <van-col span="18">{{item.handlerName}}</van-col>
          </van-row>
        </div>
        <p class="bg-line"></p>
      </div>
    </van-list>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    finish: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      page: 1
    }
  },
  methods: {
    onLoad () {
      this.$emit('page', ++this.page)
    }
  }
}
</script>

<style scoped>.bg-line {
  width: 100%;
  height: 8px;
  background-color: #f2f2f2;
  margin-bottom: 0;
}
.list-div {
  font-size: 16px;
  line-height: 1;
  line-height: 32px;
  --tw-text-opacity: 1;
  color: rgba(34, 34, 34, var(--tw-text-opacity));
  overflow-wrap: break-word;
}</style>
