<template>
  <!-- 保养记录 -->
  <div class="bg-[#f2f2f2]">
    <van-list class="list-div" @load="onLoad" :finished='finish' finished-text="没有更多了">
      <div v-for="(item,index) in data" :key="index" class="bg-[#ffffff]">
        <div class="px-16px py-14px">
          <van-row>
            <van-col class="text-[#8B8F9A]" span="6">任务编号:</van-col>
            <van-col span="18">{{item.orderNo}}</van-col>
          </van-row>
          <van-row>
            <van-col class="text-[#8B8F9A]" span="6">处理人:</van-col>
            <van-col span="18">{{item.handlerName}}</van-col>
          </van-row>
          <van-row>
            <van-col class="text-[#8B8F9A]" span="6">作业方式:</van-col>
            <van-col span="18">{{item.taskTypeName}}</van-col>
          </van-row>
          <van-row>
            <van-col class="text-[#8B8F9A]" span="6">任务描述:</van-col>
            <van-col span="18">{{item.remark}}</van-col>
          </van-row>
          <van-row>
            <van-col class="text-[#8B8F9A]" span="8">计划开始时间:</van-col>
            <van-col span="16">{{item.planStart}}</van-col>
          </van-row>
          <van-row>
            <van-col class="text-[#8B8F9A]" span="8">计划结束时间:</van-col>
            <van-col span="16">{{item.planEnd}}</van-col>
          </van-row>
          <van-row>
            <van-col class="text-[#8B8F9A]" span="8">实际开始时间:</van-col>
            <van-col span="16">{{item.actualStart}}</van-col>
          </van-row>
          <van-row>
            <van-col class="text-[#8B8F9A]" span="8">实际结束时间:</van-col>
            <van-col span="16">{{item.actualEnd}}</van-col>
          </van-row>
        </div>
        <p class="bg-line"></p>
      </div>
    </van-list>

  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    finish: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      page: 1
    }
  },
  methods: {
    onLoad () {
      this.$emit('page', ++this.page)
    }
  }
}
</script>

<style scoped>.bg-line {
  width: 100%;
  height: 8px;
  background-color: #f2f2f2;
  margin-bottom: 0;
}
.list-div {
  font-size: 16px;
  line-height: 1;
  line-height: 32px;
  --tw-text-opacity: 1;
  color: rgba(34, 34, 34, var(--tw-text-opacity));
  overflow-wrap: break-word;
}</style>
